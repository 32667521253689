import { ICloseFreightRequest, ICreateFreightRequest, ICreateFreightRequestOffer, IMeta, IMetaRequest, IRateRequestOffer, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const requestOffersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createRequestOffer: builder.mutation<{ request: IRequest }, ICreateFreightRequestOffer>({
      query: (data: ICreateFreightRequestOffer) => ({ url: `/requests/${data?.requestId}/transportation/request_offers`, method: 'POST', body: data }),
      invalidatesTags: ['Request']
    }),
    rateRequestOffer: builder.mutation<{ request: IRequest }, IRateRequestOffer>({
      query: (data: IRateRequestOffer) => ({ url: `/requests/${data?.requestId}/request_offers/${data?.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Request']
    }),
  }),
  overrideExisting: false
})

export const { useCreateRequestOfferMutation, useRateRequestOfferMutation } = requestOffersApi;
