import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, FormErrorMessage, FormControl, InputRightElement, Icon } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope, FaLock, FaPhone, FaUniversity } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useCreateCompanyMutation } from "../../state/services/companies";
import SupportedCountrySelect from "../../components/Form/SupportedCountrySelect";
import { useDispatch, useSelector } from "react-redux";
import { useGetAccessTokenMutation } from "../../state/services/auth";
import { IUserLoginRequest } from "~/resources/interfaces";
import { loginSession } from "../../helpers/sessionHelper";
import { RootState } from "../../state/store";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getCountries } from "../../resources/eu_countries";
import { CircleFlag } from "react-circle-flags";

const Register = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const currentLocale = i18n.language;
  const countries = getCountries(currentLocale);

  const { register, handleSubmit, control, getValues, setValue, watch, formState: { errors } } = useForm();
  const [createCompany, { isSuccess, isLoading }] = useCreateCompanyMutation();

  const w = watch();

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const onSubmit = (data) => {
    data.country = data?.country?.value;
    data.business_type = data?.business_type?.value;

    createCompany(data);
  };

  const { accessToken } = useSelector((state: RootState) => state.auth)
  const [getAccessToken, { isError, isLoading: isLoadingAccessToken }] = useGetAccessTokenMutation();
  const dispatch = useDispatch();

  const isAccessTokenPresent = accessToken?.length > 0;

  useEffect(() => {
    if (isAccessTokenPresent) {
      navigate('/dashboard')
    }
  }, [isAccessTokenPresent]);


  useEffect(() => {
    if (isSuccess) {
      const userData: IUserLoginRequest = {
        email: getValues('email'),
        password: getValues('password')
      };

      getAccessToken(userData).unwrap()
        .then((response) => {
          const token = response['token'];
          const refreshToken = response['refreshToken'];

          loginSession(token, refreshToken, dispatch)
        })
        .catch((error) => { });
    }
  }, [isSuccess])

  useEffect(() => {
    axios.get('https://ipapi.co/json/').then((response) => {
      let data = response.data;

      const foundCountry = countries.filter((c) => c.code == data.country_code).map((country) => ({ value: country.code, label: country.label, icon: <Icon mr={3} as={CircleFlag} countryCode={country.code.toLowerCase()} width={'20px'} /> }));
      setValue('country', foundCountry[0]);
    });

  }, [])

  return (
    <>


      <Box>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>{t('back')}</Button>

            <Spacer h={'30px'} />
            <HStack justifyContent={''}>
              <Image w={'220px'} src={'/images/logo.png'} />
              <Text fontSize={'xl'} fontWeight={'light'}>{t('register_free')}</Text>
            </HStack>

            <Divider my={5} />

            {isSuccess && <Alert status='success'>
              <AlertIcon />
              {t('registration_success')}
            </Alert>
            }

            {!isSuccess &&
              <>

                <Stack direction={['column', 'row']} alignItems={'flex-start'} spacing={10}>
                  <VStack spacing={5} w={'full'} alignItems={'flex-start'}>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('company_name')}</Text>
                      <Input w={'full'} {...register('name', { required: true })} placeholder={t('company_name_placeholder')} ></Input>
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('company_type')}</Text>
                      <Controller
                        control={control}
                        name="business_type"
                        defaultValue={null}
                        rules={{ required: t('select_company_type') }}
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { error }
                        }) => (
                          <FormControl isInvalid={!!error}>
                            <Select
                              options={[
                                { value: 'standard', label: t('production_trade') },
                                { value: 'transportation', label: t('carrier') },
                                { value: 'forwarder', label: t('forwarder') },
                              ]}
                              placeholder={t('select_company_type_placeholder')}
                              closeMenuOnSelect={true}
                              ref={ref}
                              onChange={onChange}
                              name="business_type"
                              value={value}
                              selectedOptionStyle="check"
                            />
                            <FormErrorMessage>{error && error.message}</FormErrorMessage>

                          </FormControl>
                        )} />
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('phone')}</Text>

                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          zIndex={0}
                          children={<FaPhone color='gray.300' />}
                        />
                        <Input w={'full'} type={'tel'} {...register('phone', { required: true })} placeholder={t('phone_placeholder')} />
                      </InputGroup>
                    </Box>

                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('id_number')}</Text>

                      <InputGroup>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<FaUniversity color='gray.300' />}
                        />
                        <Input type={'text'} w={'full'} {...register('id_number')} placeholder={t('id_number_placeholder')} ></Input>
                      </InputGroup>

                      <Spacer h={'10px'} />
                    </Box>


                  </VStack>
                  <Box w={'full'}>
                    <VStack spacing={8}>

                      <Box w={'full'}>
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('address')}</Text>
                        <SupportedCountrySelect control={control} name={'country'} />

                        <Spacer h={'10px'} />
                        <HStack>
                          <Input {...register('city', { required: true })} placeholder={t('city')} ></Input>
                          <Input {...register('address', { required: true })} placeholder={t('address_placeholder')} ></Input>
                        </HStack>
                      </Box>

                      <Box w={'full'} mt={6}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <Box w={'full'}>
                            <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('email')}</Text>

                            <InputGroup>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<FaEnvelope color='gray.300' />}
                              />
                              <Input type='email' autoComplete={'username'} id={'email'} name={'email'} {...register('email', { required: true })} placeholder={t('email_placeholder')} />
                            </InputGroup>
                          </Box>

                          <Box mt={5} w={'full'}>
                            <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('password')}</Text>
                            <InputGroup size='md'>
                              <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                              />
                              <Input
                                {...register('password', { required: true, minLength: 6 })}
                                pr='4.5rem'
                                type={show ? 'text' : 'password'}
                                autoComplete={'new-password'}
                                placeholder={t('password_placeholder')}
                              />
                              <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' mr={2} size='sm' onClick={handleClick}>
                                  {show ? t('hide') : t('show')}
                                </Button>
                              </InputRightElement>
                            </InputGroup>
                            <Text fontSize={'sm'} mt={2} color={'gray'}>{t('password_min_length')}</Text>
                          </Box>
                        </form>
                      </Box>
                    </VStack>
                  </Box>
                </Stack>

                <Spacer h={'25px'} />

                <Checkbox isInvalid={errors?.terms?.type?.length > 0} {...register('terms', { required: true })} color={errors?.terms?.type?.length > 0 ? 'red' : 'black'}>{t('agree_terms')} <Link target={'_blank'} textDecor={'underline'} as={ReachLink} to={'/terms'}>{t('terms_of_use')}</Link> {t('and')} <Link textDecor={'underline'} href={'/privacy'}>{t('privacy_policy')}</Link>.</Checkbox>

                <Spacer h={'25px'} />

                <Button type={'submit'} isDisabled={isLoading} onClick={handleSubmit(onSubmit)} leftIcon={<FaCheckCircle />} colorScheme={'green'}>{t('register')}</Button>
              </>}

            <Divider my={5} />

            <Text textAlign={'justify'} color={'gray.500'} fontSize={'sm'}>{t('post_registration_info')}</Text>

            <Spacer h={'20px'} />
          </Box>
        </Center>
      </Box>

    </>
  )
};

export default Register;