import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import { Route, BrowserRouter as Router, Routes, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import theme from './theme/theme';
import './locales/i18n';
import { store } from './state/store';
import AuthenticationRoutes from './components/Routes/AuthenticationRoutes';
import ProtectedRoutes from './components/Routes/ProtectedRoutes';
import DashboardLayout from './components/Layouts/Dashboard/DashboardLayout';
import Login from './views/Authentication/Login';
import AuthenticationLayout from './components/Layouts/Authentication/AuthenticationLayout';
import '@fontsource/inter';
import Dashboard from './views/StandardCompany/Dashboard';
import AdminDashboard from './views/Administration/Dashboard';
import Settings from './views/Settings';
import AdminProtectedRoutes from './components/Routes/AdminProtectedRoutes';
import FinanceProtectedRoutes from './components/Routes/TransportationBusinessProtectedRoutes';
import CustomerServiceProtectedRoutes from './components/Routes/StandardBusinessProtectedRoutes';
import Landing from './views/Landing';
import Request from './views/StandardCompany/Request';
import AdminRequests from './views/Administration/Requests';
import AdminRequest from './views/Administration/Request';

import CreateRequest from './views/StandardCompany/CreateRequest';
import Offers from './views/StandardCompany/Offers';
import AdminOffers from './views/Administration/Offers';

import StandardBusinessProtectedRoutes from './components/Routes/StandardBusinessProtectedRoutes';
import TransportationBusinessProtectedRoutes from './components/Routes/TransportationBusinessProtectedRoutes';
import TransportationDashboard from './views/TransportationCompany/Dashboard';
import TransportationRequest from './views/TransportationCompany/Request';
import CreateOffer from './views/TransportationCompany/CreateOffer';
import TransportationOffers from './views/TransportationCompany/Offers';
import TransportationOffer from './views/TransportationCompany/Offer';
import Offer from './views/StandardCompany/Offer';
import Notifications from './views/Notifications';
import AdministrationLayout from './components/Layouts/Administration/AdministrationLayout';
import Companies from './views/Administration/Companies';
import Company from './views/Administration/Company';
import Register from './views/Authentication/Register';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import Forgot from './views/Authentication/Forgot';
import ResetPassword from './views/Authentication/ResetPassword';


const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ChakraProvider theme={theme}>
          <Routes>
            {/* Landing Page */}
            <Route>
              <Route index element={<Landing />} />
              <Route path="/en" element={<Landing />} />
              <Route path="/de" element={<Landing />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
            </Route>

            {/* Authentication */}
            <Route element={<AuthenticationLayout />}>
              <Route path="/" element={<AuthenticationRoutes />}>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot" element={<Forgot />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
              </Route>
            </Route>

            <Route path="/" element={<ProtectedRoutes />}>

              <Route element={<AdministrationLayout />} >
                <Route element={<AdminProtectedRoutes />}>
                  <Route path="/admin" element={<AdminDashboard />} />
                  <Route path="/admin/requests" element={<AdminRequests />} />
                  <Route path="/admin/requests/:id" element={<AdminRequest />} />

                  <Route path="/admin/offers" element={<AdminOffers />} />
                  <Route path="/admin/companies" element={<Companies />} />
                  <Route path="/admin/companies/:id" element={<Company />} />
                  <Route path="/admin/invoices" element={<AdminDashboard />} />
                  <Route path="/admin/settings" element={<AdminDashboard />} />
                </Route>
              </Route>

              <Route element={<DashboardLayout />}>
                <Route element={<StandardBusinessProtectedRoutes />}>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/requests/:id" element={<Request />} />
                  <Route path="/create-request" element={<CreateRequest />} />
                  <Route path="/offers" element={<Offers />} />
                  <Route path="/offers/:id" element={<Offer />} />
                  <Route path="/notifications" element={<Notifications />} />
                </Route>

                <Route path="/app" element={<TransportationDashboard />} />
                <Route path="/app/dashboard" element={<TransportationDashboard />} />
                <Route path="/app/requests/:id" element={<TransportationRequest />} />

                <Route element={<TransportationBusinessProtectedRoutes />}>
                  <Route path="/app/create-offer" element={<CreateOffer />} />
                  <Route path="/app/offers" element={<TransportationOffers />} />
                  <Route path="/app/offers/:id" element={<TransportationOffer />} />
                  <Route path="/app/notifications" element={<Notifications />} />
                </Route>
              </Route>
            </Route>
          </Routes>
        </ChakraProvider>
      </Router>
    </Provider>
  )
}

export default App;
