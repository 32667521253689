import { Show, Button, Icon, Stack, Spacer, InputGroup, Text, InputLeftAddon, Input, Box, useDisclosure, HStack, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { FaSearch, FaFlag, FaTruck, FaCalendar } from "react-icons/fa";
import { isMobile } from "../../helpers/viewportHelper";
import VehicleSelect from "./VehicleSelect";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import CountrySelect from "../../components/Form/CountrySelect";
import { useTranslation } from 'react-i18next';

export const Filter = ({ filterData, setFilterData, setCurrentPage }) => {
  const { control, watch, getValues } = useForm();
  const { t } = useTranslation();

  const [query, setQuery] = useState({});
  const [filterClosed, setFilterClosed] = useState(isMobile ? true : false);
  const [selectedVehicles, setSelectedVehicles] = useState([]);

  const mappedVehicles = selectedVehicles?.map((v) => v.value)?.join(",")

  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef()

  const setFilter = () => {
    setFilterData({
      ...query,
      vehicles: mappedVehicles
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    setFilter();
  }, [query, selectedVehicles]);

  return (
    <>

      <Button ref={btnRef} onClick={onOpen} colorScheme={'blue'} leftIcon={<Icon mt={1} fontSize={'sm'} as={FaSearch} />} w={['full', 'xs']}>{t('search')}</Button>

      <Drawer
        size={'md'}
        isOpen={isOpen}
        placement={['bottom', 'right']}
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottom={'solid 1px'} borderBottomColor={'gray.300'}>{t('search')}</DrawerHeader>

          <DrawerBody>
            <form onSubmit={(e) => { e.preventDefault(); onClose() }}>

              <Text fontSize={'sm'} color={'gray'} mb={2}>{t('loading')}</Text>
              <CountrySelect defaultValue={query?.loading_country} handleOnChange={(e) => { setQuery({ ...query, loading_country: e?.value }); }} control={control} name={'loading_country'} />
              <Input defaultValue={query?.loading_info} mt={2} bg={'white'} onChange={(e) => { setQuery({ ...query, loading_info: e.target.value }); }} placeholder={t('loading_city_or_address')} />

              <Spacer h={'15px'} />

              <Text fontSize={'sm'} color={'gray'} mb={2}>{t('unloading')}</Text>
              <CountrySelect handleOnChange={(e) => { setQuery({ ...query, unloading_country: e?.value }); }} control={control} name={'unloading_country'} />
              <Input mt={2} bg={'white'} onChange={(e) => setQuery({ ...query, unloading_info: e.target.value })} placeholder={t('unloading_city_or_address')} />

              <Spacer h={'15px'} />

              <Text fontSize={'sm'} color={'gray'} mb={2}>{t('loading_date')}</Text>
              <Input mt={2} bg={'white'} onChange={(e) => setQuery({ ...query, loading_date: e.target.value })} type={'date'} placeholder={t('search_date')} />

              <Spacer h={'15px'} />

              <Text fontSize={'sm'} color={'gray'} mb={2}>{t('vehicle')}</Text>
              <Box bg={'white'} w={'full'}>
                <VehicleSelect menuPlacement="top" setSelectedVehicles={setSelectedVehicles} selectedVehicles={selectedVehicles} />
              </Box>

              <Input type={'submit'} display={'none'} />
            </form>

          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={(e) => { setQuery({}); onClose() }}>
              {t('cancel')}
            </Button>
            <Button onClick={onClose} colorScheme='blue'>{t('search')}</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>

      <Spacer h={'25px'} />

    </>
  );
};