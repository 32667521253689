import { AlertDialog, Text, AlertDialogContent, AlertDialogHeader, AlertDialogCloseButton, AlertDialogBody, Spacer, InputGroup, Input, InputRightAddon, AlertDialogFooter, Button, useToast, Flex, Box, HStack, Icon, VStack, useDisclosure, Stack, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaQuestionCircle, FaTimesCircle } from "react-icons/fa";
import { FiTruck } from "react-icons/fi";
import { IRequest, TRequestState } from "../../../resources/interfaces";
import CreateRequestOffer from "./CreateRequestOffer";
import { useTranslation } from 'react-i18next';

interface IProps {
  request: IRequest;

};

const RequestOffer = ({ request }: IProps) => {
  const { t } = useTranslation();

  const createRequestOfferDisclosure = useDisclosure();
  const offer = request?.offer;

  return (
    <>
      <CreateRequestOffer disclosure={createRequestOfferDisclosure} request={request} />

      {offer == null && request?.state != TRequestState.Closed &&
        <Flex justifyContent={'center'}>
          <VStack spacing={10}>
            <Button onClick={createRequestOfferDisclosure.onOpen} py={5} w={['full', 'sm']} leftIcon={<Icon mr={5} as={FiTruck} />} colorScheme={'red'}>{t('send_offer')}</Button>

            <Tooltip hasArrow label={t('privacy_note')}>
              <Text cursor={'pointer'}>
                <b>{t('note')}</b>: {t('offer_privacy')}
                <Icon ml={3} as={FaQuestionCircle} />
              </Text>
            </Tooltip>
          </VStack>

        </Flex>
      }

      {offer &&
        <>
          <Box border={'solid 1px gold'} _hover={{ boxShadow: 'md' }} cursor={'pointer'} w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
            <Stack direction={['column', 'row']} justifyContent={'space-evenly'} alignItems={['center', '']}>
              <VStack>
                <Text>{t('your_offer')}</Text>
                <Text fontSize={'xx-large'} fontWeight={'bold'}>{offer?.amount}</Text>
                <Text fontSize={'sm'} color={'gray.500'}>{t('sent_before', { time: offer?.createdAtBefore })}</Text>
              </VStack>

            </Stack>
          </Box>
        </>
      }
    </>
  );
}

export default RequestOffer;