import { ICloseFreightOffer, ICreateFreightOffer, IMetaRequestFreightOffer, IOffer } from "../../../resources/interfaces";
import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "../base/base";

export const adminOffersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminOffers: builder.query<{ offers: Array<IOffer>, meta: { pagination: IMeta } }, IMetaRequestFreightOffer>({
      query: (meta: IMetaRequestFreightOffer) => ({ url: `/admin/offers`, method: 'GET', params: meta }),
      providesTags: ['Offer']
    }),
  }),
  overrideExisting: false
})

export const { useGetAdminOffersQuery } = adminOffersApi;
