import React, { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../state/store";
import { isLoggedIn } from "../../helpers/sessionHelper";

const AdminProtectedRoutes = () => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.user)

  if (currentUser?.isAdmin)
    return <Outlet />;
  else {
    navigate('/404');
    return <></>;
  }

};

export default AdminProtectedRoutes;