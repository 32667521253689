import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  accessToken: string | null;
  refreshToken: string | null;
};

export type AuthPayload = {
  accessToken: string;
  refreshToken: string;
};

const slice = createSlice({
  name: 'auth',
  initialState: {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null
  } as AuthState,
  reducers: {
    setCredentials: (state, { payload: { accessToken, refreshToken } }: PayloadAction<AuthPayload>) => {
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
    },
    removeCredentials: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
    }
  }
});

export const { setCredentials, removeCredentials } = slice.actions;
export default slice.reducer;

export const currentAccessToken = (state) => state.auth.accessToken;