import { CircleFlag } from 'react-circle-flags';
import BsEUCountries from '../locales/bs/eu_countries.json'
import EnEUCountries from '../locales/en/eu_countries.json'

export const getCountries = (language: string) => {
  switch (language) {
    case 'bs':
      return BsEUCountries;
    case 'en':
      return EnEUCountries;
    default:
      return BsEUCountries;
  }
}

export const getLanguageFlag = (language: string) => {
  switch (language) {
    case 'bs':
      return "ba";
    case 'en':
      return 'us';
    case 'de':
      return 'de';
    default:
      return 'ba';
  }
}

export const findByCode = (code: string, language: string) => {
  const countries = getCountries(language);
  return countries.find((country) => country.code == (code?.toUpperCase() || 'BA'));
};
