import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Spinner, Center, Skeleton, SkeletonCircle, SkeletonText, Show, useDisclosure, AvatarBadge, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiStar, FiThumbsDown, FiThumbsUp, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TBusinessType, TDepartmentTechnicalName, TOfferState, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowLeft, FaArrowRight, FaCircle, FaFire, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useGetTransportationRequestQuery } from '../../state/services/transportation/requests';
import { CircleFlag } from 'react-circle-flags';
import { useEffect, useRef } from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Select } from 'chakra-react-select';
import { Controller, useForm } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import CloseRequest from '../StandardCompany/Request/CloseRequest';
import CreateRequestOffer from './Request/CreateRequestOffer';
import TransportationRequestOffer from './Request/RequestOffer';
import RequestOffers from '../StandardCompany/Request/RequestOffers';
import RequestInformation from '../Request/RequestInformation';
import RequestOffer from './Request/RequestOffer';
import { useGetTransportationOfferQuery } from '../../state/services/transportation/offers';
import OfferInformation from '../Offer/OfferInformation';
import CloseOffer from './Offer/CloseOffer';
import { useTranslation } from 'react-i18next';

const Offer = () => {
  const { t } = useTranslation();
  let { id } = useParams();

  const closeDisclosure = useDisclosure();

  const { data, isLoading, isSuccess } = useGetTransportationOfferQuery(parseInt(id));

  const offer = data?.offer;

  return (
    <>
      <Spacer h={'20px'} id={'view-placement'} />
      {isLoading && <>
        <Center>
          <Spinner />
        </Center>
      </>}

      {
        isSuccess && <>
          <CloseOffer disclosure={closeDisclosure} offer={offer} />

          <HStack justifyContent={'space-between'} id={'view-placement'}>
            <Button size={'sm'} as={ReachLink} to={'/app/offers'} leftIcon={<FaArrowLeft />}>{t('back')}</Button>
            {offer.state == TOfferState.Created && <Button size={'sm'} colorScheme={'red'} onClick={closeDisclosure.onOpen} leftIcon={<FaTimesCircle />}>{t('close_offer')}</Button>}
          </HStack>
          <Spacer h={'20px'} />

          <OfferInformation offer={offer} />
        </>
      }

      <Spacer h={'150px'} />
    </>
  )
};

export default Offer;