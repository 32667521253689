import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IMetaRequestFreightRequest, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const requestsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequests: builder.query<{ requests: Array<IRequest>, meta: { pagination: IMeta } }, IMetaRequestFreightRequest>({
      query: (meta: IMetaRequestFreightRequest) => ({ url: `/requests`, method: 'GET', params: meta }),
      providesTags: ['Request']
    }),
    getRequest: builder.query<{ request: IRequest }, number>({
      query: (id: number) => ({ url: `/requests/${id}`, method: 'GET' }),
      providesTags: ['Request']
    }),
    createRequest: builder.mutation<{ request: IRequest }, ICreateFreightRequest>({
      query: (data: ICreateFreightRequest) => ({ url: `/requests`, method: 'POST', body: data }),
      invalidatesTags: ['Request']
    }),
    closeRequest: builder.mutation<{ request: IRequest }, ICloseFreightRequest>({
      query: (data: ICloseFreightRequest) => ({ url: `/requests/${data.id}/close`, method: 'POST', body: data }),
      invalidatesTags: ['Request']
    }),
  }),
  overrideExisting: false
})

export const { useGetRequestsQuery, useCreateRequestMutation, useGetRequestQuery, useCloseRequestMutation } = requestsApi;
