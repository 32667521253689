import { Stack, HStack, Link, Box, Text, Icon, Button, Avatar, Tooltip, AvatarBadge, VStack, useToast } from "@chakra-ui/react"
import React from "react"
import { FaBuilding, FaPhone, FaViber } from "react-icons/fa"
import { FiPhoneCall } from "react-icons/fi"
import { useMoreInfoRequestMutation } from "../../../state/services/transportation/requests"
import { useTranslation } from 'react-i18next';

const RequestCompanyInfo = ({ request }) => {
  const { t } = useTranslation();
  const toast = useToast();

  const [requestMoreInfo, { isSuccess, isLoading }] = useMoreInfoRequestMutation();

  React.useEffect(() => {
    if (isSuccess) {
      toast({
        title: t('success'),
        description: t('contact_request_success'),
        status: 'success',
        duration: 10000,
        position: 'bottom',
        isClosable: true,
      })
    }
  }, [isSuccess])

  return (
    <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
      <Stack direction={['column', 'row', 'row', 'row']} justifyContent={'space-between'} alignItems={['center']} spacing={[5, 0]}>
        {request?.company == null && <>
          <HStack w={'full'} spacing={5} justifyContent={'space-between'}>
            <HStack spacing={10}>
              <Icon as={FaBuilding} />
              <Text>{t('company_not_shown')}</Text>
            </HStack>
            <Button isDisabled={isSuccess || isLoading} py={5} leftIcon={<Icon mr={5} as={FiPhoneCall} />} colorScheme={'gray'} onClick={() => requestMoreInfo(request.id)}>{t('request_contact')}</Button>
          </HStack>
        </>}
        {request?.company &&
          <>
            <Box>
              <HStack spacing={5}>
                <Avatar size={'md'} name={request?.company?.name} src={request?.company?.logoPath}>
                  <Tooltip textAlign={'center'} hasArrow label={t('company_rating', { rating: request?.company?.rating })}>
                    <AvatarBadge color={'black'} boxSize='2.5em' bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{request?.company?.rating}</AvatarBadge>
                  </Tooltip>
                </Avatar>
                <VStack spacing={0} alignItems={'left'}>
                  <Text fontSize={'md'}>{request?.company?.name}</Text>
                  <Text color={'gray'} fontSize={'sm'}>{request?.company?.city}</Text>
                </VStack>
              </HStack>
            </Box>
            <Box>
              <Text fontSize={'xs'}>{t('contact_phone')}</Text>
              <Text fontWeight={'bold'}>{request?.company?.phone}</Text>
            </Box>
            <Box>
              <HStack>
                <Tooltip label={t('call_company')} bg={'green'} color={'white'} hasArrow>
                  <Button borderRadius={'full'} as={Link} href={`tel:${request?.company?.phone}`} colorScheme={'green'} leftIcon={<Icon as={FaPhone} mr={3} />} aria-label={t('contact_phone')}>{t('call')}</Button>
                </Tooltip>

                <Tooltip label={t('call_company_viber')} bg={'purple'} color={'white'} hasArrow>
                  <Button borderRadius={'full'} ml={4} as={Link} href={`viber://chat?number=${request?.company?.phone}`} colorScheme={'purple'} leftIcon={<Icon as={FaViber} mr={3} />} aria-label={t('call_viber')}>{t('call_viber')}</Button>
                </Tooltip>
              </HStack>
            </Box>
          </>
        }
      </Stack>
    </Box>
  )
}

export default RequestCompanyInfo;