import { HStack, Checkbox, Text, Box, Image } from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { IVehicle } from "../../../resources/interfaces";

interface IProps {
  id: number;
  vehicle: IVehicle;
  control: any;
  controlName: string;
  defaultValue?: boolean;
};

const Vehicle = ({ id, vehicle, control, defaultValue = false }: IProps) => {

  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={`vehicles[vehicle_id_${id}]`}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <Box bg={value ? 'blue.50' : 'white'} onClick={() => onChange(!value)} cursor={'pointer'} mt={3} boxShadow={'base'} mb={3} p={5} border={'solid 1px'} borderColor={value ? 'blue.500' : 'gray.200'} borderRadius={'md'}>
          <HStack justifyContent={'space-between'} spacing={5}>
            <Box maxW={'20%'}>
              <Image mixBlendMode={'multiply'} borderRadius={'md'} src={vehicle.imagePath} w={'full'} />
            </Box>
            <Box w={'70%'}>
              <Text fontSize={'lg'} color={value ? 'blue.500' : 'gray.600'} fontWeight={value ? 'semibold' : 'light'}>{vehicle.name}</Text>
              <Text color={'gray.500'} fontSize={'xs'}>{vehicle.description}</Text>
            </Box>
            <Checkbox ref={ref} onBlur={onBlur} onChange={() => onChange(value)} isChecked={value} size={'lg'} colorScheme={'blue'}></Checkbox>
          </HStack>
        </Box>
      )} />
  );
};

export default Vehicle;