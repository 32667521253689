import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Terms = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box whiteSpace={'pre-line'}>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>{t('back')}</Button>

            <Spacer h={'30px'} />

            <Text fontSize={'xl'} fontWeight={'light'}>{t('terms_of_use')}</Text>

            <Divider my={5} />

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('general')}
            </Heading>

            <Text>
              {t('general_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('subject_of_terms')}
            </Heading>

            <Text>
              {t('subject_of_terms_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('registration_and_account')}
            </Heading>

            <Text>
              {t('registration_and_account_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('user_obligations')}
            </Heading>

            <Text>
              {t('user_obligations_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('platform_responsibilities')}
            </Heading>

            <Text>
              {t('platform_responsibilities_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('fees_and_payments')}
            </Heading>

            <Text>
              {t('fees_and_payments_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('data_protection')}
            </Heading>

            <Text>
              {t('data_protection_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('disclaimer')}
            </Heading>

            <Text>
              {t('disclaimer_text')}
            </Text>

            <Heading as="h2" size="md" mt={8} mb={4}>
              {t('changes_to_terms')}
            </Heading>

            <Text>
              {t('changes_to_terms_text')}
            </Text>
          </Box>
        </Center>
      </Box>

      <Spacer h={'100px'} />
    </>
  )
};

export default Terms;