import { createMultiStyleConfigHelpers, extendTheme } from '@chakra-ui/react';
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { tableTheme as Table } from './components/Table';
import { buttonTheme as Button } from './components/Button';
import { colors } from './colors';


const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: '#f4f8f9'
      }
    }
  },
  colors,
  fonts: {
    heading: `'Helvetica Neue', 'Inter', sans-serif`,
    body: `'Helvetica Neue', 'Inter', sans-serif`,
    button: `'Helvetica Neue', 'Inter', sans-serif`
  },
  components: {
    Steps,
    Table
  }
});

export default theme
