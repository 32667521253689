import { Badge, Box, Button, Flex, IconButton, Input, InputGroup, InputLeftElement, Skeleton, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from '@chakra-ui/react';
import {
  Pagination,
  usePagination,
  PaginationNext,
  PaginationPage,
  PaginationPrevious,
  PaginationContainer,
  PaginationPageGroup,
} from "@ajna/pagination";
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { useEffect } from 'react';
import React from 'react';
import { IMeta } from '../../resources/interfaces';

interface IProps {
  meta: IMeta;
  onPageChange: (page: number) => void;
}

const Paginator = ({ meta = { page: 1, perPage: 10, totalAmount: 0 }, onPageChange }: IProps) => {
  const totalPages = Math.ceil(meta.totalAmount / meta.perPage) || 1;

  const {
    currentPage,
    setCurrentPage,
    pagesCount,
    pages
  } = usePagination({
    limits: {
      outer: 1,
      inner: 1,
    },
    pagesCount: totalPages,
    initialState: { currentPage: 1 },
  });

  const getDisplayedIndexes = () => {
    if (meta.totalAmount === 0 || meta.totalAmount === 1) {
      return meta.totalAmount;
    }

    const firstItemIndex = (currentPage - 1) * meta.perPage + 1;
    const lastItemIndex = currentPage === pagesCount ? meta.totalAmount : currentPage * meta.perPage;
    return `${firstItemIndex}-${lastItemIndex}`;
  };

  useEffect(() => {
    onPageChange(currentPage);
  }, [currentPage])

  return (
    <>
      <Flex alignItems={'center'}>
        <Box>

          <Pagination
            pagesCount={totalPages}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          >
            <PaginationContainer>
              <PaginationPrevious mr={2} w={8} h={8}><FiChevronLeft /></PaginationPrevious>
              <PaginationPageGroup>
                {pages.map((page: number) => (
                  <PaginationPage
                    w={8}
                    h={8}
                    fontSize="sm"
                    key={`pagination_page_${page}`}
                    page={page}
                    _current={{
                      colorScheme: 'blue'
                    }}
                  />
                ))}
              </PaginationPageGroup>
              <PaginationNext ml={2} w={8} h={8}><FiChevronRight /></PaginationNext>
            </PaginationContainer>
          </Pagination>
        </Box>
      </Flex>
    </>
  );
}

export default Paginator;