import { Box } from "@chakra-ui/react";
import React, { FC, ReactNode } from "react";
import { Outlet } from "react-router-dom";

const AuthenticationLayout = () => {
  return (
    <>
      <Box w={'100%'} h={'100vh'} bg={'#eaeef4'}>
        <Outlet />
      </Box>
    </>
  );
};

export default AuthenticationLayout;