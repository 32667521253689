import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setCurrentUser } from "../../../state/slices/usersSlice";
import { useGetCurrentUserQuery } from "../../../state/services/users";
import Navigation from "./Navigation";

const DashboardLayout = () => {
  const dispatch = useDispatch();
  const { data: currentUserData, isSuccess } = useGetCurrentUserQuery(null, { pollingInterval: 5000 });

  useEffect(() => {
    if (currentUserData) {
      dispatch(setCurrentUser(currentUserData.user));

      if (typeof tidioChatApi !== undefined) {
        tidioChatApi.setVisitorData({
          email: currentUserData?.user?.email,
          name: currentUserData?.user?.company?.name,
          tags: [currentUserData?.user?.company?.businessType],
        });
      }
    }
  }, [currentUserData]);

  return (
    <>
      {isSuccess &&
        <Box w={'100%'} h={'100%'} bg={'#f4f8f9'}>
          <Navigation>
            <Outlet />
          </Navigation>
        </Box>
      }

    </>
  );
};

export default DashboardLayout;