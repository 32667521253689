import { Box, Image, Tooltip, Icon, Text, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, Show, Hide, HStack } from '@chakra-ui/react'
import * as React from 'react'
import { IRequest, TRequestLoadingType } from '../../resources/interfaces';
import { FaArrowRight, FaEllipsisV, FaFire, FaSnowflake, FaTruckLoading } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { CircleFlag } from 'react-circle-flags';
import { displayDate } from '../../helpers/dateHelper';
import { FiBell } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

interface IProps {
  request: IRequest;
}

const RequestInformation = ({ request }: IProps) => {
  const { t } = useTranslation();
  return (
    <>

      <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
        <Stack direction={['column', 'row', 'row', 'row']} justifyContent={'space-between'} spacing={[10, 0]}>
          <Box p={4} w={'full'}>
            <Box>
              <Stack flexWrap={'wrap'} alignItems={'center'} direction={'row'} justifyContent={'center'}>
                {request.vehicles.map((vehicle) => (
                  <Tooltip label={vehicle.name} hasArrow placement={'top'}>
                    <Image src={vehicle.imagePath} w={'94px'} h={'100%'} />
                  </Tooltip>
                ))}
              </Stack>
              <Flex p={5} gap={5} flexWrap={'wrap'} justifyContent={'center'}>
                {request.isFragile &&
                  <Tag size={'sm'} variant='subtle' colorScheme='red'>
                    <TagLeftIcon boxSize='12px' as={TbGlass} />
                    <TagLabel fontWeight={'bold'}>{t('fragile')}</TagLabel>
                  </Tag>}

                {request.isAdr &&
                  <Tag size={'sm'} variant='subtle' colorScheme='red'>
                    <TagLeftIcon boxSize='12px' as={FaFire} />
                    <TagLabel fontWeight={'bold'}>{t('adr')}</TagLabel>
                  </Tag>}

                {request.isFrigo &&
                  <Tag size={'sm'} variant='subtle' colorScheme='blue'>
                    <TagLeftIcon boxSize='12px' as={FaSnowflake} />
                    <TagLabel fontWeight={'bold'}>{t('frigo')}</TagLabel>
                  </Tag>}

                {request.isRamp &&
                  <Tag size={'sm'} variant='subtle' colorScheme='green'>
                    <TagLeftIcon boxSize='12px' as={FaTruckLoading} />
                    <TagLabel fontWeight={'bold'}>{t('ramp')}</TagLabel>
                  </Tag>}
              </Flex>
            </Box>
            <Stack direction={['column', 'row']} mt={5} justifyContent={'space-evenly'} alignItems={['flex-start', 'center']} spacing={[0, '30px']}>
              <Box>
                <Stack direction={['row', 'column']} spacing={[5, 0]}>
                  <CircleFlag countryCode={request.loadingCountry.toLowerCase()} width={'24px'} />
                  <Box m={0}>
                    <Text fontSize={'md'} mt={2} fontWeight={'bold'}>{request.loadingCity}</Text>
                    <Text fontSize={'sm'} color={'gray'}>{request.loadingAddress}</Text>
                  </Box>
                </Stack>
              </Box>
              <Show above={'sm'}>
                <Icon as={FaArrowRight} />
              </Show>
              <Hide above={'sm'}>
                <Icon textAlign={'center'} as={FaEllipsisV} />
              </Hide>
              <Box>
                <Stack direction={['row', 'column']} spacing={[5, 0]}>
                  <CircleFlag countryCode={request.unloadingCountry.toLowerCase()} width={'24px'} />
                  <Box m={0}>
                    <Text fontSize={'md'} mt={2} fontWeight={'bold'}>{request.unloadingCity}</Text>
                    <Text fontSize={'sm'} color={'gray'}>{request.unloadingAddress}</Text>
                  </Box>
                </Stack>
              </Box>
            </Stack>
            {request.note &&
              <>
                <Divider my={3} mb={5} />
                <Box borderRadius={'md'} bg={'gray.100'} border={'dashed 1px'} borderColor={'gray.400'} p={3}>
                  <HStack mb={4}>
                    <Icon as={FiBell} />
                    <Text fontSize={'xs'} fontWeight={'bold'}>{t('note')}</Text>
                  </HStack>
                  {request.note}
                </Box>
              </>
            }
          </Box>
          <Table w={'full'} size={'sm'} mt={5} variant={'striped'}>
            <Tbody>
              <Tr>
                <Td>{t('request_number')}</Td>
                <Td>23{request.id}</Td>
              </Tr>
              <Tr>
                <Td>{t('goods_description')}</Td>
                <Td>{request.goodsDescription}</Td>
              </Tr>
              <Tr>
                <Td>{t('loading_date')}</Td>
                <Td>{displayDate(request.loadingDate)}</Td>
              </Tr>
              {request.unloadingDate &&
                <Tr>
                  <Td>{t('unloading_date')}</Td>
                  <Td>{displayDate(request.unloadingDate)}</Td>
                </Tr>
              }
              <Tr>
                <Td>{t('weight')}</Td>
                <Td>{request.weight} kg</Td>
              </Tr>
              <Tr>
                <Td>{t('pallets')}</Td>
                <Td>{request.pallets}</Td>
              </Tr>
              <Tr>
                <Td>{t('packages')}</Td>
                <Td>{request.packages}</Td>
              </Tr>
              <Tr>
                <Td>{t('dimensions')}</Td>
                <Td>
                  {t('length')}: {request.dimensionsLength || 0} cm |
                  {t('width')}: {request.dimensionsWidth || 0} cm |
                  {t('height')}: {request.dimensionsHeight || 0} cm
                </Td>
              </Tr>
              <Tr>
                <Td>{t('space')}</Td>
                <Td>{request.space} m³</Td>
              </Tr>
              <Tr>
                <Td>{t('loading_option')}</Td>
                <Td>
                  {request.loadingType == TRequestLoadingType.FTL && t('ftl')}
                  {request.loadingType == TRequestLoadingType.LTL && t('ltl')}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Stack>
      </Box >

    </>
  )
};

export default RequestInformation;