import { IMeta, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<{ users: Array<IUser>, meta: { pagination: IMeta } }, void>({
      query: () => ({ url: '/users' }),
      providesTags: ['User']
    }),
    getCurrentUser: builder.query<{ user: IUser }, void>({
      query: () => ({ url: '/user' }),
      providesTags: ['User']
    }),
    refreshCurrentUser: builder.mutation<{ user: IUser }, void>({
      query: () => ({ url: '/user' }),
      invalidatesTags: ['User']
    }),
  }),
  overrideExisting: false
})

export const { useGetCurrentUserQuery, useRefreshCurrentUserMutation, useGetUsersQuery } = usersApi;
