import { IMeta, IUser, IUserCreateRequest, IVehicle } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const vehiclesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVehicles: builder.query<{ vehicles: Array<IVehicle>, meta: { pagination: IMeta } }, void>({
      query: () => ({ url: '/vehicles' }),
      providesTags: ['Vehicle']
    })
  }),
  overrideExisting: false
})

export const { useGetVehiclesQuery } = vehiclesApi;
