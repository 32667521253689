import { ReactNode } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  Image,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Container,
  Text,
  Icon,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  usePopoverContext,
  Spacer,
  Input,
  Badge,
  AvatarBadge,
  Show,
  Hide,
  VStack,
} from '@chakra-ui/react';
import { FaHamburger, FaTimesCircle, FaPlusCircle, FaHome, FaChevronDown, FaBell } from 'react-icons/fa';
import React from 'react';
import { FiArrowRightCircle, FiAward, FiBox, FiCheckCircle, FiCheckSquare, FiChevronDown, FiDisc, FiExternalLink, FiFilePlus, FiFlag, FiHome, FiMenu, FiPackage, FiPhone, FiPlusCircle, FiPrinter, FiRefreshCcw, FiSettings, FiShield, FiTruck, FiUser } from 'react-icons/fi';
import { GrMoney } from 'react-icons/gr';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { MdOutlineSwapHorizontalCircle, MdOutlineCheckCircle } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import NavigationItem from './ Navigation/NavigationItem';
import { logoutSession } from '../../../helpers/sessionHelper';
import { TBusinessType } from '../../../resources/interfaces';
import { useTranslation } from 'react-i18next';
import i18n from '../../../locales/i18n';
import { CircleFlag } from 'react-circle-flags';
import { getLanguageFlag } from '../../../resources/eu_countries';

const Links = ['home', 'assignments', 'deassignments'];

const NavLink = ({ children }: { children: ReactNode }) => (
  <Link
    color={'white'}
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}
    href={'#'}>
    {children}
  </Link>
);

export const LanguageSwitcher = ({ isDark = true }) => {

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<FaChevronDown color='gray' />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} bg={isDark ? 'rgba(255, 255, 255, 0.2)' : 'gray.300'} colorScheme={isDark ? 'blackAlpha' : 'whiteAlpha'}>
        <CircleFlag countryCode={getLanguageFlag(i18n.language)} width={'16px'} />
      </MenuButton>
      <MenuList borderRadius={'md'} boxShadow={isDark ? 'black 0px 0px 34px' : 'white 0px 0px 34px'} p={0} bg={isDark ? 'black' : 'white'} border={'solid 1px'} borderColor={isDark ? 'gray.800' : 'gray.100'}>
        <MenuItem onClick={() => i18n.changeLanguage('en')} _hover={{ bg: isDark ? '#57575f' : 'gray.100' }} bg={isDark ? '#3e3e48' : 'white'} p={5}>
          <CircleFlag style={{ marginRight: '10px' }} countryCode="us" width={'16px'} /> English
        </MenuItem>
        <MenuItem onClick={() => i18n.changeLanguage('de')} _hover={{ bg: isDark ? '#57575f' : 'gray.100' }} bg={isDark ? '#3e3e48' : 'white'} p={5}>
          <CircleFlag style={{ marginRight: '10px' }} countryCode="de" width={'16px'} /> Deutsch
        </MenuItem>
        <MenuItem onClick={() => i18n.changeLanguage('bs')} _hover={{ bg: isDark ? '#57575f' : 'gray.100' }} bg={isDark ? '#3e3e48' : 'white'} p={5}>
          <CircleFlag style={{ marginRight: '10px' }} countryCode="ba" width={'16px'} /> Bosnian
        </MenuItem>
      </MenuList>
    </Menu>
  )
};

export default function Navigation({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const logout = () => {
    logoutSession(dispatch)
  };

  const notificationsUrl = currentUser?.company?.businessType == TBusinessType.Standard ? '/notifications' : '/app/notifications'

  return (
    <>
      <Box py={5} boxShadow={'lg'} color={'white'} borderBottom={'solid 1px lightgrey'} bg={'#0E0E1A'} px={4}>
        <Container maxW={'container.xl'} py={5}>
          <HStack justifyContent={'space-between'}>
            <Image w={['164px', '256px']} src={'/images/logo-white.png'} />
            <Show above={'md'}>
              <HStack>
                <Button as={ReachLink} to={notificationsUrl} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<Icon as={FaBell} />} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>
                  <Text>{t('notifications')}</Text>
                  {currentUser?.company?.notificationsCount > 0 &&
                    <Badge px={'4px'} colorScheme={'red'} bg={'red.500'} color={'white'} borderRadius={'50%'} ml={3}>{currentUser?.company?.notificationsCount}</Badge>}
                </Button>
                <LanguageSwitcher />

              </HStack>

            </Show>
            <Hide above={'md'}>
              <HStack justifyContent={'flex-end'} w={'full'} spacing={3}>
                <IconButton as={ReachLink} to={notificationsUrl} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} borderRadius={'50%'} bg={'gray.700'} aria-label={''} icon={<Icon as={FaBell} />} colorScheme={'blackAlpha'}>
                </IconButton>
                {currentUser?.company?.notificationsCount > 0 &&
                  <Badge position={'relative'} zIndex={100} mt={'30px'} px={'5px'} colorScheme={'red'} bg={'red.500'} color={'white'} borderRadius={'50%'} ml={3}>{currentUser?.company?.notificationsCount}</Badge>
                }
                <LanguageSwitcher />
              </HStack>
            </Hide>

          </HStack>
          <Spacer h={'20px'} />
          <HStack>
            <Text fontWeight={300} fontSize={'24px'}>{t('welcome_to_the_platform', { companyName: currentUser?.company?.name })}</Text>
            <Link onClick={logout} fontSize={'sm'}>({t('logout')})</Link>
          </HStack>
          <Spacer h={'20px'} />

          <Stack direction={['column', 'row', 'row', 'row']}>
            {currentUser?.company?.businessType == TBusinessType.Standard && <>
              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  {t('goods_and_cargo')}
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/app'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPackage} />} bg={'#3e3e48'} p={5}>{t('all_goods_and_cargo_offers')}</MenuItem>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/dashboard'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiUser} />} bg={'#3e3e48'} p={5}>{t('my_goods_and_cargo_offers')}</MenuItem>
                  <MenuItem as={ReachLink} to={'/create-request'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>{t('add_new_goods_or_cargo_offer')}</MenuItem>
                </MenuList>
              </Menu>
              <Button as={ReachLink} to={'/create-request'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPlusCircle />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>{t('add_new_goods_or_cargo_offer')}</Button>
              <Button as={ReachLink} to={'/offers'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiTruck />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>{t('available_vehicles')}</Button>
            </>}

            {currentUser?.company?.businessType == TBusinessType.Transportation && <>
              <Button as={ReachLink} to={'/app'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>{t('goods_and_cargo_offers')}</Button>
              <Button as={ReachLink} to={'/app/offers'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiFlag />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>{t('my_free_vehicles')}</Button>
              <Button as={ReachLink} to={'/app/create-offer'} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPlusCircle />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'blackAlpha'}>{t('add_new_free_vehicle')}</Button>
            </>}

            {currentUser?.company?.businessType == TBusinessType.Forwarder && <>

              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiPackage />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  {t('goods_and_cargo')}
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/app'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPackage} />} bg={'#3e3e48'} p={5}>{t('all_goods_and_cargo_offers')}</MenuItem>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/dashboard'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiUser} />} bg={'#3e3e48'} p={5}>{t('my_goods_and_cargo_offers')}</MenuItem>
                  <MenuItem as={ReachLink} to={'/create-request'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>{t('add_new_goods_or_cargo_offer')}</MenuItem>
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton as={Button} rightIcon={<FaChevronDown />} fontSize={'md'} _hover={{ bg: 'rgba(255, 255, 255, 0.3)' }} leftIcon={<FiTruck />} iconSpacing={5} size={'lg'} bg={'rgba(255, 255, 255, 0.2)'} colorScheme={'bl'}>
                  {t('vehicles')}
                </MenuButton>
                <MenuList boxShadow={'black 0px 0px 34px'} p={0} bg={'black'} border={'solid 1px'} borderColor={'gray.800'}>
                  <MenuItem borderBottom={'solid 2px #2e2e37'} as={ReachLink} to={'/offers'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiTruck} />} bg={'#3e3e48'} p={5}>{t('free_vehicles')}</MenuItem>
                  <MenuItem as={ReachLink} to={'/app/create-offer'} _hover={{ bg: '#57575f' }} icon={<Icon as={FiPlusCircle} />} bg={'#3e3e48'} p={5}>{t('add_new_free_vehicle')}</MenuItem>
                </MenuList>
              </Menu>

            </>}

            {currentUser?.isAdmin && <>
              <Button as={ReachLink} to={'/admin'} fontSize={'md'} leftIcon={<FiShield />} iconSpacing={5} size={'lg'} colorScheme={'red'}>{t('administration')}</Button>
            </>}

            <Button as={Link} href={'https://cargo.ba/obuka-za-transportne-speditere/'} target={'_blank'} fontSize={'md'} rightIcon={<FiExternalLink />} leftIcon={<FiAward />} iconSpacing={5} size={'lg'} colorScheme={'orange'}>
              <VStack spacing={0} alignItems={'flex-start'}>
                <Text>{t('training_for_transport_forwarders')}</Text>
                <Text fontSize={'xs'}>Cargo.ba</Text>
              </VStack>

            </Button>
          </Stack>
        </Container>

        {isOpen ? (
          <Box pb={4} display={{ md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link) => (
                <NavLink key={link}>{t(link)}</NavLink>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>

      <Container maxW={'container.xl'}>
        {children}
      </Container>
    </>
  );
}