import React, { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../state/store";
import { isLoggedIn } from "../../helpers/sessionHelper";
import { TBusinessType, TDepartmentTechnicalName } from "../../resources/interfaces";

const TransportationBusinessProtectedRoutes = () => {
  const navigate = useNavigate();
  const { currentUser } = useSelector((state: RootState) => state.user)

  if ([TBusinessType.Transportation, TBusinessType.Forwarder].includes(currentUser?.company?.businessType))
    return <Outlet />;
  else
    navigate('/dashboard')
};

export default TransportationBusinessProtectedRoutes;