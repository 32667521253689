import { HStack, Button, Select as ChakraSelect, Spacer, Box, Text, Avatar, VStack, Input, Center, InputGroup, InputLeftAddon, InputLeftElement, Select, AvatarBadge, Tooltip, useToast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FaArrowLeft, FaCheckCircle, FaEnvelope, FaPhone } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { useForm } from "react-hook-form";
import { useGetAdminRequestQuery } from "../../state/services/admin/requests";
import { useGetCompanyQuery, useUpdateCompanyMutation } from "../../state/services/admin/companies";
import { CircleFlag } from "react-circle-flags";
import CountrySelect from "../../components/Form/CountrySelect";
import Users from "./Company/Users";
import { displayDate } from "../../helpers/dateHelper";

const Company = () => {
  let { id } = useParams();
  const { register, handleSubmit, control, getValues } = useForm();
  const toast = useToast();

  const { data, isLoading, isSuccess } = useGetCompanyQuery(parseInt(id), { refetchOnMountOrArgChange: true });

  const [updateCompany, { isSuccess: isSuccessUpdate }] = useUpdateCompanyMutation();
  const company = data?.company;

  const onSubmit = (data) => {
    data.id = id;

    if (data?.country?.value?.length > 0) {
      data.country = data.country.value;
    }

    updateCompany(data);
  };

  const onFileChange = (e) => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("logo", e.target.files[0]);
    updateCompany(formData);
  }

  useEffect(() => {
    if (isSuccessUpdate) {
      toast({
        title: 'Uspješno!',
        description: "Uspješno ste sačuvali izmjene.",
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isSuccessUpdate])

  return (
    <>
      <HStack justifyContent={'space-between'} id={'view-placement'}>
        <Button size={'sm'} as={ReachLink} to={'/admin/companies'} leftIcon={<FaArrowLeft />}>Nazad</Button>
        <HStack>
          <Button onClick={handleSubmit(onSubmit)} colorScheme={'green'} leftIcon={<FaCheckCircle />} size={'sm'}>Spremi izmjene</Button>

        </HStack>
      </HStack>
      <Spacer h={'20px'} />

      {isSuccess &&
        <Tabs>
          <TabList>
            <Tab>Informacije</Tab>
            <Tab>Korisnici/Licence</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <form onSubmit={handleSubmit(onSubmit)}>

                <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
                  <HStack flexDirection={['column', 'row']} alignItems={'flex-start'} spacing={[0, 10]}>
                    <Box w={'full'}>
                      <Center>
                        <Avatar size={'lg'} name={company?.name} src={company?.logoPath}>
                          <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${company?.rating} od 5 na osnovu prethodni vožnji.`}>
                            <AvatarBadge boxSize='2.5em' color={'black'} bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{company?.rating}</AvatarBadge>
                          </Tooltip>
                        </Avatar>
                      </Center>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Naziv kompanije</Text>

                      <HStack w={'full'} spacing={5}>
                        <VStack w={'full'} spacing={0} alignItems={'left'}>
                          <Input w={'full'} {...register('name', { required: true })} placeholder={'Naziv kompanije'} defaultValue={company?.name}></Input>
                        </VStack>
                      </HStack>

                      <Spacer h={'50px'} />

                      <Box>
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Adresa</Text>
                        <CountrySelect defaultValue={company?.country} control={control} name={'country'} />
                        <Spacer h={'10px'} />
                        <HStack>
                          <Input {...register('city', { required: true })} placeholder={'Grad'} defaultValue={company?.city}></Input>
                          <Input {...register('address', { required: true })} placeholder={'Adresa'} defaultValue={company?.address}></Input>
                        </HStack>
                      </Box>

                      <Spacer h={'50px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Logotip</Text>
                      <Input onChange={onFileChange} type={'file'} />
                    </Box>
                    <Box w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum registracije</Text>
                      <Text>{displayDate(company?.createdAt)}</Text>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Status</Text>

                      <ChakraSelect bg={company.approved ? 'green.200' : 'red.200'} {...register('approved')} defaultValue={company?.approved ? 1 : 0}>
                        <option value={1}>Aktivan nalog</option>
                        <option value={0}>Isključen nalog</option>
                      </ChakraSelect>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Vrsta kompanije</Text>

                      <ChakraSelect  {...register('business_type')} defaultValue={company?.businessType}>
                        <option value={'standard'}>Standardna kompanija (proizvodna ili trgovinska)</option>
                        <option value={'transportation'}>Prijevoznik</option>
                        <option value={'forwarder'}>Špediter</option>

                      </ChakraSelect>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Kontakt podaci</Text>

                      <HStack>
                        <Input defaultValue={company?.phone} w={'full'} {...register('phone', { required: true })} type='text' placeholder='Broj telefona (+38762000000)' />

                        <InputGroup>
                          <InputLeftElement
                            pointerEvents='none'
                            children={<FaEnvelope color='gray.300' />}
                          />
                          <Input type='email' defaultValue={company.email} {...register('email', { required: true })} placeholder='E-Mail' />
                        </InputGroup>
                      </HStack>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>ID Broj</Text>
                      <Input w={'full'} {...register('id_number', { required: true })} placeholder={'ID Broj firme'} defaultValue={company?.idNumber}></Input>

                      <Spacer h={'20px'} />

                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>PDV Broj</Text>
                      <Input w={'full'} {...register('vat_number', { required: true })} placeholder={'PDV Broj firme'} defaultValue={company?.vatNumber}></Input>

                    </Box>
                  </HStack>
                </Box>
              </form>
            </TabPanel>
            <TabPanel>
              <Users company={company} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      }
    </>
  )
};

export default Company;