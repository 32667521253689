import { Box, Image, Container, HStack, Icon, Spacer, Text, Tooltip, Button, Avatar, AvatarGroup, Stack, Center, Table, Tbody, Td, Th, Thead, Tr, TableContainer, VStack, Tag, Show, Divider, AvatarBadge, Badge, Spinner, IconButton } from '@chakra-ui/react'
import * as React from 'react'
import { BsStars } from 'react-icons/bs';
import { FaCheckCircle, FaCircle, FaDollarSign, FaTruck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCurrentUserQuery } from '../state/services/users';
import { TNotificationType } from '../resources/interfaces';
import { useClearNotificationsMutation, useGetNotificationsQuery } from '../state/services/notifications';
import { RootState } from '../state/store';
import { NotificationIcon } from './Notifications/NotificationIcon';
import { setCurrentUser } from '../state/slices/usersSlice';
import { useEffect } from 'react';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Notifications = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: currentUserData, refetch, isSuccess: isSuccessUser } = useGetCurrentUserQuery();
  const { data, isLoading } = useGetNotificationsQuery({ page: 1, per_page: 10 }, { refetchOnMountOrArgChange: true });

  const [clearNotifications, { isLoading: isLoadingClear, isSuccess }] = useClearNotificationsMutation();

  const clear = () => {
    clearNotifications();
  }

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess])

  useEffect(() => {
    if (currentUserData) {
      dispatch(setCurrentUser(currentUserData?.user));
    }
  }, [currentUserData])

  return (
    <>

      <Spacer h={'30px'} />


      {data?.notifications?.length > 0 &&
        <Center>
          <Button disabled={isLoadingClear} onClick={() => clear()} leftIcon={<Icon as={BsStars} />} colorScheme={'green'}>{t('clear_notifications')}</Button>
        </Center>
      }

      <Spacer h={'30px'} />

      <Container maxW={'container.sm'}>
        {isLoading && <Spinner />}

        <>
          {data?.notifications?.map((notification) => (
            <Box onClick={() => navigate(notification?.hyperlink)} _hover={{ bg: 'gray.50' }} cursor={'pointer'} mb={3} p={[3, 5]} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} borderRadius={'md'} boxShadow={'md'}>
              <Stack alignItems={'center'} direction={['column', 'row']} spacing={5}>
                <AvatarGroup spacing='0.5rem'>
                  <NotificationIcon type={notification?.notificationType || TNotificationType.Generic} />
                </AvatarGroup>

                <VStack w={'full'} alignItems={['center', 'flex-start']}>
                  <Text textAlign={['center', 'left']} color={'gray.700'} fontWeight={'semibold'}>{notification.title}</Text>
                  <Text textAlign={['center', 'left']} fontSize={'sm'} color={'gray'}>{notification.description}</Text>
                </VStack>

                <Text m={0} p={0} fontSize={'xs'} color={'gray'}>{t('time_ago', { time: notification.createdAtBefore })}</Text>
              </Stack>
            </Box>
          ))}
        </>

        <Spacer h={'30px'} />

        <Center>
          {data?.notifications?.length == 0 &&
            <VStack>
              <Icon fontSize={'xl'} color={'green'} as={FaCheckCircle} />
              <Text>{t('no_new_notifications')}</Text>
            </VStack>
          }
        </Center>
      </Container>

      <Spacer h={'30px'} />

    </>
  )
};

export default Notifications;