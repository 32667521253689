import { HStack, Text } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";

export const iconComponents = {
  Option: ({ children, ...props }) => (
    <chakraComponents.Option {...props}>
      {props.data.icon} {children}
    </chakraComponents.Option>
  ),
  SingleValue: ({ children, ...props }) => (
    <chakraComponents.SingleValue {...props}>
      <HStack>
        {props.data.icon}
        <Text>{children}</Text>
      </HStack>
    </chakraComponents.SingleValue>
  )
};

