import { Avatar, Icon } from "@chakra-ui/react";
import React from "react";
import { FaCheckCircle, FaCoins, FaDollarSign, FaInfo, FaStar, FaTimes, FaTimesCircle, FaTrophy, FaTruck } from "react-icons/fa";
import { TNotificationType } from "../../resources/interfaces";

export const NotificationIcon = ({ type }) => {
  return (
    <>
      {type == TNotificationType.Generic &&
        <Avatar bg='blue.500' size={['sm', 'md']} icon={<Icon as={FaInfo} />} />}

      {type == TNotificationType.NewRequests &&
        <Avatar bg='orange.500' size={['sm', 'md']} icon={<Icon as={FaTruck} />} />}

      {type == TNotificationType.NewOffer &&
        <Avatar bg='green.500' size={['sm', 'md']} icon={<Icon as={FaCoins} />} />}

      {type == TNotificationType.AcceptedOffer &&
        <Avatar bg='green.500' size={['sm', 'md']} icon={<Icon as={FaTrophy} />} />}

      {type == TNotificationType.ClosedRequestNotAccepted &&
        <Avatar bg='red.500' size={['sm', 'md']} icon={<Icon as={FaTimesCircle} />} />}

      {type == TNotificationType.RateTransportationCompany &&
        <Avatar bg='orange.300' size={['sm', 'md']} icon={<Icon as={FaStar} />} />}


    </>
  );
};