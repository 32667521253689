import { IUserLoginRequest, IUserLoginResponse } from "~/resources/interfaces";
import { baseApi } from "./base/base";

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAccessToken: builder.mutation<IUserLoginResponse, IUserLoginRequest>({
      query: (data: IUserLoginRequest) => ({
        url: '/auth/token',
        method: 'POST',
        body: data
      })
    }),
    forgotPassword: builder.mutation<void, { email: string }>({
      query: (data: { email: string }) => ({ url: `/auth/forgot_password`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
    resetPassword: builder.mutation<void, { email: string, code: string }>({
      query: (data: { email: string, code: string }) => ({ url: `/auth/reset_password`, method: 'POST', body: data }),
      invalidatesTags: ['User']
    }),
  }),
  overrideExisting: false
})

export const { useResetPasswordMutation, useForgotPasswordMutation, useGetAccessTokenMutation } = authApi;
