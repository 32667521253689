import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnglishTranslations from './en/translations.json';
import BosnianTranslations from './bs/translations.json';
import GermanTranslations from './de/translations.json';

import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  bs: {
    translation: BosnianTranslations
  },
  en: {
    translation: EnglishTranslations
  },
  de: {
    translation: GermanTranslations
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "bs",
    detection: {
      // Use localStorage or cookies for persistence
      order: ['localStorage', 'cookie', 'navigator'],
      caches: ['localStorage', 'cookie'],
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;