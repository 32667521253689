import React from "react";
import { useStore } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAdmin, isLoggedIn } from "../../helpers/sessionHelper";

const AuthenticationRoutes = () => {
  const location = useLocation();
  const store = useStore();

  const storeAuthState = store.getState().auth;
  const isLoggedInStore = storeAuthState?.accessToken?.length > 0

  if (!isLoggedIn && !isLoggedInStore)
    return <Outlet />;

  return <Navigate to="/dashboard" replace state={{ from: location }} />;
};

export default AuthenticationRoutes;