export const colors = {
  primary: {
    "50": "#daf1ff",
    "100": "#b9dae8",
    "200": "#99bed0",
    "300": "#77a3b8",
    "400": "#5d8ea5",
    "500": "#05303f",
    "600": "#356c82",
    "700": "#26586c",
    "800": "#194557",
    "900": "#05303f"
  }
};
