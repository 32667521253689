import { Box, Text, Center, Image, Input, Divider, Spacer, HStack, VStack, InputGroup, InputLeftElement, Button, Stack, Alert, AlertIcon, Checkbox, Link, Heading, UnorderedList, ListItem } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FaArrowLeft, FaCheckCircle, FaEnvelope } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box>
        <Center>
          <Box p={10} mt={[0, '10vh']} w={['full', 'container.lg']} bg={'white'} borderRadius={'lg'} boxShadow={'lg'}>
            <Button size={'sm'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>{t('back')}</Button>

            <Spacer h={'30px'} />

            <Text fontSize={'xl'} fontWeight={'light'}>{t('privacy_policy')}</Text>

            <Divider my={5} />

            <Text>{t('privacy_intro')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('data_collection')}</Heading>
            <Text>{t('data_collection_details')}</Text>
            <Text mt={2}>{t('data_usage')}</Text>
            <Text mt={2}>{t('cookies_usage')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('data_usage_heading')}</Heading>
            <Text>{t('data_usage_details')}</Text>
            <Text>{t('marketing_communication')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('data_sharing_heading')}</Heading>
            <Text>{t('data_sharing_details')}</Text>

            <UnorderedList>
              <ListItem>{t('data_sharing_users')}</ListItem>
              <ListItem>{t('data_sharing_partners')}</ListItem>
              <ListItem>{t('data_sharing_service_providers')}</ListItem>
              <ListItem>{t('data_sharing_regulatory')}</ListItem>
            </UnorderedList>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('data_security_heading')}</Heading>
            <Text>{t('data_security_details')}</Text>
            <Text mt={2}>{t('data_security_measures')}</Text>
            <Text mt={2}>{t('data_storage')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('user_rights_heading')}</Heading>
            <Text>{t('user_rights_details')}</Text>
            <Text mt={2}>{t('user_rights_complaint')}</Text>
            <Text mt={2}>{t('data_retention')}</Text>
            <Text mt={2}>{t('contact_info')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('cookies_tracking_heading')}</Heading>
            <Text>{t('cookies_tracking_details')}</Text>
            <Text>{t('cookies_browser_settings')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('privacy_policy_changes_heading')}</Heading>
            <Text>{t('privacy_policy_changes_details')}</Text>

            <Heading as="h2" size="md" mt={8} mb={4}>{t('conclusion_heading')}</Heading>
            <Text>{t('conclusion_details')}</Text>
          </Box>
        </Center>
      </Box>

      <Spacer h={'100px'} />
    </>
  )
};

export default Privacy;