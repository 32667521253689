import { HStack, Icon, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import { CircleFlag } from "react-circle-flags";
import { getCountries } from '../../resources/eu_countries';
import { Controller } from "react-hook-form";
import { iconComponents } from "../../resources/icon_select";
import { useTranslation } from 'react-i18next';

const SupportedCountrySelect = ({ control, name, defaultValue = null }) => {
  const { i18n } = useTranslation();
  const currentLocale = i18n.language;
  const countries = getCountries(currentLocale);
  const mappedCountries = countries.filter(
    (c) => [
      'BA', 'HR', 'RS', 'ME', 'SI', 'AL', 'MK',
      'AD', 'AT', 'BE', 'BG', 'CH', 'CY', 'CZ',
      'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB',
      'GR', 'HU', 'IE', 'IS', 'IT', 'LI', 'LT',
      'LU', 'LV', 'MC', 'MT', 'NL', 'NO', 'PL',
      'PT', 'RO', 'SE', 'SK', 'SM', 'VA', 'XK',
      'AM', 'AZ', 'BY', 'GE', 'MD', 'UA', 'RU',
      'TR'
    ].includes(c.code)
  ).map((country) => ({ value: country.code, label: country.label, icon: <Icon mr={3} as={CircleFlag} countryCode={country.code.toLowerCase()} width={'20px'} /> }));
  const defaultCountry = mappedCountries.filter((country) => country.value == (defaultValue || 'BA'))[0]

  return (
    <Controller
      control={control}
      defaultValue={defaultCountry}
      name={name}
      key={defaultCountry}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { error }
      }) => (
        <Select
          options={mappedCountries}
          chakraStyles={{
            menu: (provided, state) => ({
              ...provided,
              zIndex: 999
            })
          }}
          placeholder="Odaberite opciju..."
          closeMenuOnSelect={true}
          ref={ref}
          onChange={onChange}
          key={defaultCountry}
          onBlur={onBlur}
          value={value}
          components={iconComponents}
          noOptionsMessage={() => 'Nema dostupnih opcija.'}
          selectedOptionStyle="check"
        />
      )} />
  );
};

export default SupportedCountrySelect;