import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./services/auth";
import authReducer from "./slices/authSlice";
import userReducer from './slices/usersSlice';
import { baseApi } from "./services/base/base";

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    auth: authReducer,
    user: userReducer
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat([baseApi.middleware])
});

export type RootState = ReturnType<typeof store.getState>
