import React, { useEffect, useState } from "react";
import { useCreateUserMutation, useDeleteUserMutation, useUpdateUserMutation } from "../../../state/services/admin/users";
import { Alert, Avatar, AvatarBadge, Badge, Box, Button, Center, Flex, FormControl, FormLabel, Heading, Icon, IconButton, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Spacer, Stack, Table, TableCaption, TableContainer, Tag, Tbody, Td, Text, Tfoot, Th, Thead, Tr, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import { FiCrosshair, FiDelete, FiEdit, FiPlusCircle, FiX } from "react-icons/fi";
import { useForm } from "react-hook-form";
import { IAdminCompany, TDepartmentTechnicalName } from "../../../resources/interfaces";

interface IProps {
  company: IAdminCompany;
}

const Users = ({ company }: IProps) => {
  const [createUser, { isSuccess, isError }] = useCreateUserMutation();

  const [updateUser] = useUpdateUserMutation();
  const [deleteUser] = useDeleteUserMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { register, handleSubmit, watch, getValues, reset, formState: { errors, dirtyFields } } = useForm();

  const w = watch();

  const [editUser, setEditUser] = useState(null);

  const onSubmit = data => {
    if (editUser != null) {
      let updateData = { id: editUser.id };
      Object.keys(dirtyFields).map((key) => updateData[key] = data[key])

      updateUser(updateData);
    } else {
      data.company_id = company.id;
      createUser(data);
    }

    onClose();

  };

  const onEditClick = (user) => {
    setEditUser(user);
    onOpen();
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      onClose();
      setEditUser(null);
    }
  }, [isSuccess])

  return (
    <>
      <Button colorScheme={'green'} size={'sm'} onClick={() => { setEditUser(null); onOpen(); reset(); }}><Icon mr={3} as={FiPlusCircle} /> Dodaj korisnika</Button>

      <Spacer h={'20px'} />

      <TableContainer borderRadius={'md'} boxShadow={'base'}>
        <Table wordBreak={'break-word'} boxShadow={'base'} variant={'primary'}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Korisničko ime</Th>
              <Th>E-Mail</Th>
              <Th>Akcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {company?.users?.map((user) => (
              <Tr key={user.id}>
                <Td><Badge>{user.id}</Badge></Td>
                <Td>{user.username}</Td>
                <Td>{user.email}</Td>
                <Td>
                  <Button colorScheme={'blue'} onClick={() => onEditClick(user)} mr={4} size={'sm'}><Icon as={FiEdit} /></Button>
                  <Button colorScheme={'red'} onClick={() => deleteUser(user.id)} size={'sm'}><Icon as={FiX} /></Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editUser == null ? 'Unos korisnika' : `Izmjena korisnika ${editUser?.username}`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex
                align={'center'}
                justify={'center'}
              >
                <Stack
                  spacing={4}
                  w={'full'}
                  maxW={'md'}
                  rounded={'xl'}
                  my={4}>

                  {isError && <Alert colorScheme={'red'}>Dogodila se greška! Provjerite podatke.</Alert>}

                  <FormControl id="userName" isRequired={editUser == null}>
                    <FormLabel>Korisničko ime</FormLabel>
                    <Input
                      {...register('username')}
                      placeholder=""
                      defaultValue={editUser?.username}
                      _placeholder={{ color: 'gray.500' }}
                      type="text"
                    />
                  </FormControl>
                  <FormControl id="email" isRequired>
                    <FormLabel>E-Mail Adresa</FormLabel>
                    <Input
                      {...register('email')}
                      defaultValue={editUser?.email}
                      _placeholder={{ color: 'gray.500' }}
                      type="email"
                    />
                  </FormControl>
                  <FormControl id="password" isRequired={editUser == null}>
                    <FormLabel>Šifra</FormLabel>
                    <Input
                      autoComplete="false"
                      {...register('password')}
                      _placeholder={{ color: 'gray.500' }}
                      type="password"
                    />
                  </FormControl>

                  <Spacer h={8} />
                  <Stack spacing={6} direction={['column', 'row']}>
                    <Button
                      bg={'red.400'}
                      color={'white'}
                      w="full"
                      onClick={() => { setEditUser(null); onClose(); reset(); }}
                      _hover={{
                        bg: 'red.500',
                      }}>
                      Odustani
                    </Button>
                    <Button
                      type="submit"
                      bg={'blue.400'}
                      color={'white'}
                      w="full"
                      _hover={{
                        bg: 'blue.500',
                      }}>
                      Sačuvaj
                    </Button>
                  </Stack>
                </Stack>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
};

export default Users;