import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";
import { setCurrentUser } from "../../../state/slices/usersSlice";
import { useGetCurrentUserQuery } from "../../../state/services/users";
import Navigation from "../Dashboard/Navigation";
import SidebarWithHeader from "../Dashboard/SidebarWithHeader";

const AdministrationLayout = () => {
  const dispatch = useDispatch();
  const { data: currentUserData, isSuccess } = useGetCurrentUserQuery(null, { pollingInterval: 5000 });

  useEffect(() => {
    if (currentUserData) {
      dispatch(setCurrentUser(currentUserData.user));
    }
  }, [currentUserData]);

  return (
    <>
      {isSuccess &&
        <Box w={'100%'} h={'100%'} bg={'#f4f8f9'}>
          <SidebarWithHeader>
            <Outlet />
          </SidebarWithHeader>
        </Box>
      }

    </>
  );
};

export default AdministrationLayout;