import { IRequestOffer, IUpdateRequestOffer } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const adminRequestOffersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRequestOffer: builder.mutation<{ request_offer: IRequestOffer }, IUpdateRequestOffer>({
      query: (data: IUpdateRequestOffer) => ({ url: `/admin/requests/${data?.requestId}/request_offers/${data?.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['RequestOffer', 'Request']
    }),
    deleteRequestOffer: builder.mutation<{ request_offer: IRequestOffer }, { requestId: number, requestOfferId: number }>({
      query: (data) => ({ url: `/admin/requests/${data?.requestId}/request_offers/${data?.requestOfferId}`, method: 'DELETE' }),
      invalidatesTags: ['RequestOffer', 'Request']
    }),
  }),
  overrideExisting: false
})

export const { useDeleteRequestOfferMutation, useUpdateRequestOfferMutation } = adminRequestOffersApi;
