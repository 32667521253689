import { DateTime } from 'luxon';

export const displayDate = (date) => {
  return DateTime.fromISO(date).toFormat("dd.MM.yyyy");;
};

export const todayDate = () => {
  return DateTime.now().toFormat("yyyy-MM-dd");;
}

export const todayDatePlus = (days: number) => {
  return DateTime.now().plus({ days: days }).toFormat("yyyy-MM-dd");;
}

export const valueDate = (date) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd");;
}