import { Container, Stack, Icon, Box, Image, Text, Flex, VStack, HStack, Spacer, Badge, Tag, Link, Button, Input, Show, Divider, Center } from '@chakra-ui/react'
import { BsArrowRightShort, BsStars, BsTruck } from "react-icons/bs";
import { FiAward, FiBell, FiExternalLink, FiTruck } from 'react-icons/fi';
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { FaArrowDown, FaArrowRight, FaCheckCircle, FaCoins, FaEllipsisV, FaFire, FaInfo, FaRegCalendarAlt, FaRoute, FaShippingFast, FaSitemap, FaSnowflake, FaStore, FaTruck, FaTruckLoading, FaUsers, FaViber } from 'react-icons/fa';
import React from 'react';
import { CircleFlag } from 'react-circle-flags';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import CountrySelect from '../components/Form/CountrySelect';
import { useForm } from 'react-hook-form';
import { TbGlass } from 'react-icons/tb';
import { RiShieldFlashFill } from 'react-icons/ri';

import { displayDate } from '~/helpers/dateHelper';
import { TRequestLoadingType } from '../resources/interfaces';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageSwitcher } from '../components/Layouts/Dashboard/Navigation';


export default function Landing() {
  const { t } = useTranslation();
  const { register, handleSubmit, control, getValues } = useForm();

  const addresses = [
    { country: 'ba', city: 'Sarajevo', address: 'Džemala Bijedića 280' },
    { country: 'de', city: 'Bernau', address: 'Jahnstrasse 74' },
    { country: 'ba', city: 'Tuzla', address: 'Ive Andrića 10' },
    { country: 'de', city: 'München', address: 'Rhinstrasse 82' },
    { country: 'de', city: 'Weilheim', address: 'Bleibtreustraße 60' },
    { country: 'ba', city: 'Bihać', address: 'Cazinska 11' },
    { country: 'ba', city: 'Bihać', address: 'Cazinska 11' },
    { country: 'hr', city: 'Zagreb', address: 'Aleja Lipa 54' },
    { country: 'rs', city: 'Beograd', address: 'Plavska 19' }
  ];

  return (

    <>
      <Box py={3} bg={'gray.600'} color={'white'}>
        <Container maxW={'container.xl'}>
          <Stack justif direction={['column', 'row', 'row']} spacing={10}>
            <a href="https://cargo.ba/obuka-za-transportne-speditere/" target="_blank">
              <Image src={'http://cargo.ba/wp-content/uploads/2017/08/1-3.png'} p={1} borderRadius={'md'} bg={'white'} />
            </a>
            <VStack alignItems={'left'}>
              <Text fontWeight={'bold'}>{t('training_for_transport_forwarders')}</Text>
              <Text>
                {t('training_description')}
              </Text>
            </VStack>

            <Spacer />

            <Box>
              <Button as={Link} target="_blank" href={'https://cargo.ba/obuka-za-transportne-speditere/'} colorScheme={'orange'} rightIcon={<Icon as={FiExternalLink} />}>{t('apply_for_training')}</Button>

            </Box>

          </Stack>

        </Container>
      </Box>

      <Box zIndex={1000} bgSize={'cover'} pt={5} bgImage={['', '/images/bg_2.png']} bgRepeat={'no-repeat'} bgPosition={'right'} boxShadow={'lg'} >
        <Container maxW={'container.xl'}>
          <Stack alignItems={'center'} direction={['column', 'row']} gap={[3, 10]} justifyContent={'space-between'}>
            <Box justifyContent={'flex-start'} w={'full'}>
              <Image mx={['auto', '0']} maxW={240} src={'/images/logo.png'} alt={''} />
            </Box>
            <Box w={'full'}>
              <Stack spacing={10} justifyContent={'center'} direction={'row'}>
                <Link href={'/#'}>info@etransport.ba</Link>
              </Stack>
            </Box>
            <Box w={'full'} textAlign={'right'}>
              <HStack justifyContent={['center', 'flex-end']}>
                <Link fontSize={'md'} as={ReachLink} to={'/register'} mr={5}>{t('register')}</Link>
                <Button size={'md'} as={ReachLink} to={'/login'} colorScheme={'blue'} variant={'solid'} >
                  {t('login')}
                  <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
                </Button>

                <LanguageSwitcher isDark={false} />
              </HStack>


            </Box>
          </Stack>
        </Container>

        <Spacer h={['50px', '50px']} />

        <Box p={3} w={'full'}>
          <Container maxW={'container.xl'} p={0}>
            <VStack maxW={['center', 'container.md']} alignItems={['center', 'flex-start']} spacing={6}>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'blue.500'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>

                    <Icon as={FaStore} fontSize={'48px'} />
                    <Box>
                      <Text>{t('production_trade_companies')}</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>{t('need_transport')}</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    {t('open_transport_request')}
                  </Button>
                </Stack>
              </Box>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'orange'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>
                    <Icon as={FaTruck} fontSize={'48px'} />
                    <Box>
                      <Text>{t('carriers')}</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>{t('have_free_vehicle')}</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    {t('view_goods_offers')}
                  </Button>
                </Stack>
              </Box>

              <Box flexDir={['column', 'row']} w={'full'} borderRadius={5} bg={'teal'} p={5} color={'white'}>
                <Stack direction={['column', 'row']} justifyContent={'space-between'} spacing={5} alignItems={'center'}>
                  <Stack direction={['column', 'row']} spacing={5} alignItems={'center'}>
                    <Icon as={FaUsers} fontSize={'48px'} />
                    <Box>
                      <Text>{t('forwarders')}</Text>
                      <Text fontSize={'2xl'} fontWeight={'bold'}>{t('expand_business')}</Text>
                    </Box>
                  </Stack>

                  <Button as={ReachLink} to={'/register'} rightIcon={<FaArrowRight />} colorScheme={'blackAlpha'}>
                    {t('register')}
                  </Button>
                </Stack>
              </Box>
            </VStack>
          </Container>
        </Box >
        <Spacer h={['50px', '100px']} />
      </Box>

      <Spacer h={'50px'} />

      <Container maxW={'container.xl'} id={'transport'} p={5}>
        <Text textAlign={'center'} fontSize={'xl'} fontWeight={'bold'}>{t('welcome_message')}</Text>

        <Text mt={5}>{t('profit_description')}</Text>

        <Text mt={5}>{t('platform_description')}</Text>

        <Spacer h={'50px'} />

        <Center mb={10}>
          <Button target={'_blank'} as={Link} href={'https://invite.viber.com/?g2=AQAGj3Gw9SVE3lDcjuo41C31mjuYlX8B%2BdZpDxh0FqPikaxvM0a8eptnBoYulTA%2B'} leftIcon={<Icon as={FaViber} />} colorScheme={'purple'}>{t('join_viber_channel')}</Button>
        </Center>

      </Container>

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('find_carrier')}</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                {t('save_time')}
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                {t('register_as_company')}
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <VStack>

                  <Image borderRadius={'lg'} boxShadow={'base'} src={'/images/europe.jpg'} />
                  <Text fontSize={'xs'} color={'gray'}>{t('find_goods_in_europe')}</Text>

                </VStack>
              </Center>
            </Box>
          </Stack>
        </Box>

      </Container>

      <Spacer h={['0px', '100px']} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('simple_overview')}</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                {t('track_requests')}
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                {t('register_as_carrier')}
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'left'}>
              <Show above={'sm'}>
                <TableContainer borderRadius={'md'} boxShadow={'base'}>
                  <Table boxShadow={'base'} variant={'primary'}>
                    <Thead>
                      <Tr>
                        <Th>{t('date')}</Th>
                        <Th>{t('pickup')}</Th>
                        <Th>{t('delivery')}</Th>
                        <Th textAlign={'center'}>{t('info')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {[1, 2, 3, 4, 5, 6].map((i) => {
                        const loading = addresses[Math.floor(Math.random() * addresses.length)];
                        const unloading = addresses[Math.floor(Math.random() * addresses.length)];

                        return (
                          <Tr fontSize={'sm'}>
                            <Td>20.02.2024</Td>
                            <Td>
                              <HStack spacing={5}>
                                <CircleFlag countryCode={loading.country} width={'16px'} />
                                <VStack alignItems={'left'} spacing={0}>
                                  <Text>{loading.city}</Text>
                                  <Text color={'gray'} fontSize={'xs'}>{loading.address}</Text>
                                </VStack>
                              </HStack>
                            </Td>
                            <Td>
                              <HStack spacing={5}>
                                <CircleFlag countryCode={unloading.country} width={'16px'} />
                                <VStack alignItems={'left'} spacing={0}>
                                  <Text>{unloading.city}</Text>
                                  <Text color={'gray'} fontSize={'xs'}>{unloading.address}</Text>
                                </VStack>
                              </HStack>
                            </Td>
                            <Td textAlign={'center'}>
                              <Button as={ReachLink} to={'/register'} colorScheme={'blue'} size={'xs'} leftIcon={<FiTruck />}>
                                {t('send_offer')}
                              </Button>
                            </Td>
                          </Tr>
                        )
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </Show>
              <Show below='md'>
                <Text mb={5} textAlign={'center'} fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('simple_overview')}</Text>

                <Box mb={5} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} py={5} px={6} borderRadius={'md'} boxShadow={'md'}>
                  <HStack alignItems={'flex-start'}>
                    <HStack w={'full'} spacing={6}>
                      <VStack spacing={4}>
                        <CircleFlag countryCode={'ch'} width={'16px'} />
                        <Icon as={FaEllipsisV} />
                        <CircleFlag countryCode={'de'} width={'16px'} />
                      </VStack>
                      <VStack alignItems={'left'}>
                        <HStack spacing={5}>
                          <VStack spacing={0} alignItems={'left'}>
                            <Text>Zürich</Text>
                            <Text color={'gray'} fontSize={'xs'}>Bahnhofstrasse 31</Text>
                          </VStack>
                        </HStack>
                        <Spacer h={'20px'} />
                        <HStack spacing={5}>
                          <VStack spacing={0} alignItems={'left'}>
                            <Text>Berlin</Text>
                            <Text color={'gray'} fontSize={'xs'}>Bernauer Straße 10</Text>
                          </VStack>
                        </HStack>
                      </VStack>
                    </HStack>
                    <Box>
                      <HStack justifyContent={'flex-end'} mb={2}>
                        <Icon as={FaFire} color={'red.500'} />
                        <Icon as={FaSnowflake} color={'blue.500'} />
                        <Icon as={FaTruckLoading} color={'green'} />
                        <Icon as={TbGlass} color={'orange.700'} />
                      </HStack>
                      <VStack alignItems={'flex-end'}>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'green'}>Zbirni tr.</Tag>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>4500 kg</Tag>
                        <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>5 paleta</Tag>
                      </VStack>
                    </Box>
                  </HStack>

                  <Divider my={4} />

                  <HStack justifyContent={'space-between'}>
                    <HStack spacing={5}>
                      <Icon as={FaRegCalendarAlt} />
                      <VStack alignItems={'left'} spacing={0}>
                        <Text fontSize={'sm'}>16.04.2023</Text>
                        <Text color={'gray'} fontSize={'xs'}>Datum utovara</Text>
                      </VStack>
                    </HStack>
                    <VStack alignItems={'flex-end'} textAlign={'right'}>
                      <Image alt={'šleper'} cursor={'pointer'} src={'/images/vehicles/semitruck-hard-cover.webp'} mixBlendMode={'multiply'} w={'48px'} />

                      <Text textAlign={'right'} color={'gray'} fontSize={'xs'}>Mega Šleper</Text>
                    </VStack>
                  </HStack>

                  <Divider my={4} />

                  <HStack justifyContent={'space-between'}>
                    <HStack spacing={5}>


                      <Text color={'gray'} fontSize={'xs'} p={0} mb={2} textAlign={'center'}>
                        Objavljeno prije 2 sata.
                      </Text>
                    </HStack>
                    <Box textAlign={'right'} mt={5}>
                      <Button as={ReachLink} to={`/register`} size={'sm'} colorScheme={'blue'}>Pregledaj</Button>
                    </Box>
                  </HStack>
                </Box>
              </Show>
            </Box>
          </Stack>
        </Box>

      </Container>


      <Spacer h={'100px'} />

      <Box bg={'whitesmoke'} boxShadow={'lg'}>
        <Container maxW={'container.xl'} py={10}>
          <Box p={5} w={'full'}>
            <Stack spacing={10} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
              <Show below={'md'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('for_companies')}</Text>
              </Show>

              <Box py={10} px={5} bg={'white'} boxShadow={'md'} borderRadius={'lg'} w={'full'} textAlign={'left'}>
                <VStack spacing={0} justifyContent={'space-between'} >
                  <Box>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('pickup')}</Text>
                    <CountrySelect defaultValue={'CH'} control={control} name={'loading_country'} />
                    <Spacer h={'10px'} />
                    <HStack>
                      <Input defaultValue={'Zürich'} placeholder={'Grad'}></Input>
                      <Input defaultValue={'Bahnhofstrasse 31'} placeholder={'Adresa'}></Input>
                    </HStack>
                  </Box>

                  <Box pt={5}>
                    <Icon fontSize={'20px'} as={FaArrowDown} mb={'5px'} />
                  </Box>

                  <Box>
                    <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>{t('delivery')}</Text>

                    <CountrySelect control={control} defaultValue={'DE'} name={'unloading_country'} />
                    <Spacer h={'10px'} />
                    <HStack>
                      <Input defaultValue={'Berlin'} placeholder={'Grad'}></Input>
                      <Input defaultValue={'Bernauer Straße 10'} placeholder={'Adresa'}></Input>
                    </HStack>
                  </Box>
                  <Box>
                    <Button as={ReachLink} to={'/register'} mt={10} leftIcon={<FaCheckCircle />} colorScheme={'green'}>{t('create_request')}</Button>
                  </Box>
                </VStack>
              </Box>
              <Box w={'full'} textAlign={['center', 'left']}>
                <Show above={'sm'}>
                  <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('for_companies')}</Text>
                </Show>

                <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                  {t('create_request_description')}
                  <br />
                </Text>
                <Button mb={5} as={ReachLink} to={'/register'} whiteSpace={'break-spaces'} mt={10} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                  {t('register_as_company')}
                  <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
                </Button>
              </Box>
            </Stack>
          </Box>
        </Container>
      </Box>


      <Spacer h={'100px'} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('efficient_for_forwarders')}</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                {t('innovative_marketplace')}
                <br />
              </Text>
              <Button as={ReachLink} to={'/register'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                {t('register_as_forwarder')}
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <Image maxW={'275px'} src={'/images/phone.png'} />
              </Center>
            </Box>
          </Stack>
        </Box>
      </Container>

      <Spacer h={'100px'} />

      <Container maxW={'container.xl'} id={'transport'} p={0}>
        <Box p={5} w={'full'}>
          <Stack flexDir={['column-reverse', 'row']} direction={['column', 'row']} alignItems={'flex-start'} justifyContent={'center'}>
            <Box w={'full'} textAlign={['center', 'left']}>
              <Show above={'sm'}>
                <Text fontSize={'xx-large'} lineHeight={'50px'} fontWeight={'bold'}>{t('our_partners')}</Text>
              </Show>
              <Text mt={10} fontSize={'20px'} color={'gray.600'} maxW={'md'}>
                {t('contact_us')}
                <br />
              </Text>
              <Button as={Link} to={'mailto:info@etransport.ba'} mt={10} whiteSpace={'break-spaces'} p={8} _hover={{ bg: 'black' }} bg={'black'} color={'white'}>
                info@etransport.ba
                <Icon fontSize={20} ml={3} as={BsArrowRightShort} />
              </Button>
            </Box>
            <Box w={'full'} textAlign={'center'}>
              <Center>
                <VStack spacing={5}>
                  <a href="http://cargo.ba" target="_blank">
                    <Image src={'http://cargo.ba/wp-content/uploads/2017/08/1-3.png'} />
                  </a>
                  <Text fontSize={'xs'}>{t('cargo_ba_description')}</Text>
                </VStack>
              </Center>
            </Box>
          </Stack>
        </Box>
      </Container>

      <Spacer h={'50px'} />

      <Container maxW={'container.md'}>

        <Spacer h={'50px'} />

        <HStack justifyContent={'space-between'}>
          <HStack>
            <CircleFlag countryCode={'eu'} width={'36px'} />

            <Text fontSize={'xs'}>
              © eTransport.ba 2024.
            </Text>
          </HStack>

          <Box fontSize={'xs'}>
            <Link href={'/privacy'}>{t('privacy_policy')}</Link>
            <Link href={'/terms'} ml={5}>{t('terms_of_use')}</Link>
          </Box>
        </HStack>
      </Container>

      <Spacer h={'50px'} />
    </>
  )
}
