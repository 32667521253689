import { removeCredentials, setCredentials } from "../state/slices/authSlice";

export const storageAccessToken = localStorage.getItem('accessToken');

export const getStorageAccessToken = () => {
  return localStorage.getItem('accessToken');
};

export const isLoggedIn = storageAccessToken?.length > 0;

export const loginSession = (token, refreshToken, callback) => {
  callback(
    setCredentials({
      accessToken: token,
      refreshToken: refreshToken
    })
  )

  localStorage.setItem('accessToken', token);

  if (refreshToken != undefined)
    localStorage.setItem('refreshToken', refreshToken);
};

export const logoutSession = (callback) => {
  callback(removeCredentials());

  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  window.location.reload();
};