import { Input, Box, Text, Image, TableContainer, Table, Thead, Tr, Th, Tbody, Td, HStack, VStack, Tag, Tooltip, Stack, Avatar, Badge, Button, AvatarBadge } from "@chakra-ui/react";
import React, { useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useGetCompaniesQuery } from "../../state/services/admin/companies";
import Paginator from "../../components/Common/Paginator";
import { displayDate } from "../../helpers/dateHelper";
import { TBusinessType, TRequestLoadingType } from "../../resources/interfaces";
import { useGetAdminOffersQuery } from "../../state/services/admin/offers";
import { FaBuilding, FaTruck } from "react-icons/fa";
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';

const Companies = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('')

  const { data: requestsData } = useGetCompaniesQuery({ per_page: 7, page: currentPage, query: query }, { refetchOnMountOrArgChange: true });

  return (
    <>
      <Input onChange={(e) => setQuery(e.target.value)} mb={5} w={'sm'} placeholder={'Pretraga...'} />

      <TableContainer borderRadius={'md'} boxShadow={'base'}>
        <Table boxShadow={'base'} variant={'primary'}>
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>Naziv</Th>
              <Th>Broj telefona</Th>
              <Th>Adresa</Th>
              <Th>Vrsta</Th>
              <Th>Status</Th>
              <Th>Akcije</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requestsData?.companies?.map((company) => (
              <Tr>
                <Td><Badge>{company.id}</Badge></Td>
                <Td>
                  <HStack spacing={5}>
                    <Avatar size={'sm'} name={company?.name} src={company?.logoPath}>
                      <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${company?.rating} od 5 na osnovu prethodni vožnji.`}>
                        <AvatarBadge boxSize='2em' bg={'gold'} color={'black'} fontSize={'9px'} fontWeight={'bold'}>{company?.rating}</AvatarBadge>
                      </Tooltip>
                    </Avatar>
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontSize={'sm'}>{company?.name}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td><Badge>{company.phone}</Badge></Td>
                <Td>
                  <HStack spacing={5}>
                    <CircleFlag countryCode={company?.country?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontWeight={'bold'} fontSize={'sm'}>{company.city}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{company.address}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td>
                  {company.businessType == TBusinessType.Standard && <Tag colorScheme={'blue'}>
                    <HStack>
                      <FaBuilding />
                      <Text>Standardna</Text>
                    </HStack>
                  </Tag>}

                  {company.businessType == TBusinessType.Transportation && <Tag colorScheme={'gray'}>
                    <HStack>
                      <FaTruck />
                      <Text>Prijevoznik</Text>
                    </HStack>
                  </Tag>}

                  {company.businessType == TBusinessType.Forwarder && <Tag colorScheme={'orange'}>
                    <HStack>
                      <FaTruck />
                      <Text>Špediter</Text>
                    </HStack>
                  </Tag>}
                </Td>
                <Td>
                  {company?.approved && <Badge colorScheme={'green'}>Aktivan</Badge>}
                  {!company?.approved && <Badge colorScheme={'red'}>Neaktivan</Badge>}
                </Td>

                <Td>
                  <Button as={ReachLink} to={`/admin/companies/${company.id}`} size={'sm'} colorScheme={company.approved ? 'blue' : 'red'} variant={'solid'}>Pregledaj</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box py={5} mb={5} textAlign={'left'}>
        <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
      </Box>
    </>
  )
};

export default Companies;