
enum TransformTypes {
  CamelCase = 'camel',
  SnakeCase = 'snake'
}

const snakeKeyToCamel = (key: string) => key.replace(/_([a-z])/g, (_, m) => m.toUpperCase());
export const camelKeyToSnake = (key: string) => key.replace(/[A-Z]/g, (m) => `_${m.toLowerCase()}`);

const transformKeysTo = (type: TransformTypes, obj: any) => {
  if (typeof obj != 'object') return obj;

  for (const oldName in obj) {
    const newName =
      type === TransformTypes.SnakeCase ? camelKeyToSnake(oldName) : snakeKeyToCamel(oldName);

    if (newName != oldName) {
      // Check for the old property name to avoid a ReferenceError
      if (Object.prototype.hasOwnProperty.call(obj, oldName)) {
        obj[newName] = obj[oldName];
        delete obj[oldName];
      }
    }

    // Use recursion to transform nested objects
    if (typeof obj[newName] == 'object') {
      obj[newName] = transformKeysTo(type, obj[newName]);
    }
  }
  return obj;
};

export const downloadFile = (filename, result) => {

  var hiddenElement = document.createElement('a');
  var url = window.URL || window.webkitURL;
  var blobPDF = url.createObjectURL(result.data);
  hiddenElement.href = blobPDF;
  hiddenElement.target = '_blank';
  hiddenElement.download = `${filename}`;
  hiddenElement.click();
}

export const toCamelCase = (obj: any) => transformKeysTo(TransformTypes.CamelCase, obj);
export const toSnakeCase = (obj: any) => transformKeysTo(TransformTypes.SnakeCase, obj);
