import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query'
import { RootState } from '../../store';
import { toSnakeCase, toCamelCase } from '../../../helpers/apiHelper';
import { removeCredentials, setCredentials } from '../../slices/authSlice';
import { Mutex } from 'async-mutex'
import { loginSession, logoutSession } from '../../../helpers/sessionHelper';

const mutex = new Mutex();
const baseQuery = fetchBaseQuery(
  {
    baseUrl: "/api/v1",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.accessToken;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    }
  }
);

export const baseQueryWithOptions: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  args['body'] = toSnakeCase(args['body']);
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()

      try {
        const refreshToken = localStorage.getItem('refreshToken');

        const refreshResult = await baseQuery({ url: '/auth/refresh_token', method: 'POST', body: { refresh_token: refreshToken } }, api, extraOptions)
        if (refreshResult.data) {
          const token = refreshResult.data.token;
          const refreshToken = refreshResult.data.refresh_token;

          loginSession(token, refreshToken, api.dispatch)

          result = await baseQuery(args, api, extraOptions)
        } else {
          logoutSession(api.dispatch)
        }
      } finally {
        release()
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  result.data = toCamelCase(result.data);

  return result;
}
