import React from "react";
import { useStore } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isLoggedIn } from "../../helpers/sessionHelper";

const ProtectedRoutes = () => {
  const location = useLocation();
  const store = useStore();
  const isLoggedInStore = store.getState().auth?.accessToken?.length > 0

  if (!isLoggedIn && !isLoggedInStore)
    return <Navigate to={{ pathname: "/login", search: `?returnUrl=${encodeURIComponent(location.pathname)}` }} replace state={{ from: location }} />;

  return <Outlet />;
};

export default ProtectedRoutes;