import { Box, Image, Container, HStack, Icon, Spacer, Text, Tooltip, Button, Avatar, AvatarGroup, Stack, Center, Table, Tbody, Td, Th, Thead, Tr, TableContainer, VStack, Tag, Show, Divider, AvatarBadge, Badge, Spinner } from '@chakra-ui/react'
import * as React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../state/store';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { FaArrowRight, FaBox, FaCalendar, FaCircle, FaCoins, FaEllipsisV, FaEye, FaFire, FaLocationArrow, FaMapMarker, FaMapMarkerAlt, FaMarker, FaMoneyBill, FaPallet, FaRegCalendarAlt, FaSnowflake, FaTruck, FaTruckLoading, FaTruckMoving } from 'react-icons/fa';
import { CircleFlag } from 'react-circle-flags';
import { useGetRequestsQuery } from '../../state/services/requests';
import { useEffect, useState } from 'react';
import Paginator from '../../components/Common/Paginator';
import { displayDate } from '../../helpers/dateHelper';
import { TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { TbGlass } from 'react-icons/tb';
import { Filter } from '../Components/Filter';
import { isMobile } from '../../helpers/viewportHelper';
import { getCountries, findByCode } from '../../resources/eu_countries';
import { FiCalendar } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';


const Dashboard = () => {
  const { t, i18n } = useTranslation();
  const currentLocale = i18n.language;
  const { currentUser } = useSelector((state: RootState) => state.user)

  const [perPage, setPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({})

  const { data: requestsData, isFetching } = useGetRequestsQuery({ per_page: perPage, page: currentPage, ...filterData }, { refetchOnMountOrArgChange: true, pollingInterval: 10000 });
  const truncate = (str, len) => str?.slice?.(0, len);

  React.useEffect(() => {
    const handleScroll = () => {
      if (
        isMobile &&
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        setPerPage(perPage + 10);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);

  }, [perPage]);

  return (
    <>
      <Spacer h={'30px'} />

      {false && <>
        <Center>
          <HStack spacing={5}>
            <Icon color={'gray.500'} as={FaTruck} />
            <Text color={'gray.500'} fontWeight={'light'} fontSize={'21px'}>{t('no_open_offers')}</Text>
          </HStack>
        </Center>
        <Center>
          <Button as={ReachLink} to={'/create-request'} mt={10} colorScheme={'green'} leftIcon={<Icon as={FaBox} mr={3} />}>{t('add_new_offer')}</Button>
        </Center>
      </>}

      <Text mb={5} fontSize={'20px'} fontWeight={'light'}>{t('your_requests')}</Text>

      <Filter filterData={filterData} setFilterData={setFilterData} setCurrentPage={setCurrentPage} />

      <Show above={'md'}>
        <TableContainer borderRadius={'md'} boxShadow={'base'}>
          <Table wordBreak={'break-word'} boxShadow={'base'} variant={'primary'}>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t('pickup')}</Th>
                <Th>{t('delivery')}</Th>
                <Th>{t('loading_date')}</Th>
                <Th>{t('loading_option')}</Th>
                <Th>{t('offer')}</Th>
                <Th>{t('vehicle')}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {requestsData?.requests?.map((request) => (
                <Tr>
                  <Td><Badge>{request?.id}</Badge></Td>
                  <Td>
                    <Tooltip hasArrow label={findByCode(request?.loadingCountry, currentLocale)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={request?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{findByCode(request?.loadingCountry, currentLocale)?.label}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{truncate(request.loadingCity, 30)}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip hasArrow label={findByCode(request?.unloadingCountry, currentLocale)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={request?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{findByCode(request?.unloadingCountry, currentLocale)?.label}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{truncate(request.unloadingCity, 30)}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>

                  <Td>
                    <Tag border={'lightgray 1px dashed'} fontSize={'sm'}>
                      <Icon mr={2} as={FiCalendar} />
                      <Text>
                        {displayDate(request.loadingDate)}
                      </Text>
                    </Tag>
                  </Td>

                  <Td>
                    <Text fontSize={'sm'}>
                      {request.loadingType == TRequestLoadingType.FTL && <Tag fontSize={'xs'} colorScheme={'green'}>{t('ftl_full_load')}</Tag>}
                      {request.loadingType == TRequestLoadingType.LTL && <Tag fontSize={'xs'} colorScheme={'red'}>{t('ltl_partial_load')}</Tag>}
                    </Text>
                  </Td>
                  <Td>
                    {request.offers?.length > 0 &&
                      <VStack spacing={0}>
                        <Text fontSize={'xs'} color={'gray'}>{t('last_offer')}</Text>
                        <Text fontSize={'sm'}>{request.offers[request.offers.length - 1]?.amount}</Text>
                      </VStack>}
                    {request.offers?.length == 0 && <Text textAlign={'center'} fontSize={'xs'} color={'gray'}>{t('no_offers')}</Text>}
                  </Td>
                  <Td>
                    <Tooltip label={<Stack>
                      {request.vehicles.map((v) => <Text>{v.name}</Text>)}
                    </Stack>} placement={'bottom'} hasArrow>
                      <Image cursor={'pointer'} src={request?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'48px'} />
                    </Tooltip>
                  </Td>
                  <Td>
                    <Button as={ReachLink} to={`/requests/${request.id}`} size={'sm'} colorScheme={'blue'} variant={'solid'}>{t('view')}</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        <Box py={5} mb={5} textAlign={'left'}>
          <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
        </Box>
      </Show>

      <Show below={'md'}>
        {
          requestsData?.requests?.map((request) => (
            <Box mb={5} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} py={5} px={6} borderRadius={'md'} boxShadow={'md'}>
              <HStack alignItems={'flex-start'}>
                <HStack w={'full'} spacing={6}>
                  <VStack spacing={4}>
                    <CircleFlag countryCode={request?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <Icon as={FaEllipsisV} />
                    <CircleFlag countryCode={request?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                  </VStack>
                  <VStack alignItems={'left'}>
                    <HStack spacing={5}>
                      <VStack spacing={0} alignItems={'left'}>
                        <Text>{request.loadingCity}</Text>
                        <Text color={'gray'} fontSize={'xs'}>{request.loadingAddress}</Text>
                      </VStack>
                    </HStack>
                    <Spacer h={'20px'} />
                    <HStack spacing={5}>
                      <VStack spacing={0} alignItems={'left'}>
                        <Text>{request.unloadingCity}</Text>
                        <Text color={'gray'} fontSize={'xs'}>{request.unloadingAddress}</Text>
                      </VStack>
                    </HStack>
                  </VStack>
                </HStack>
                <Box>
                  <HStack justifyContent={'flex-end'} mb={2}>
                    {request.isAdr && <Icon as={FaFire} color={'red.500'} />}
                    {request.isFrigo && <Icon as={FaSnowflake} color={'blue.500'} />}
                    {request.isRamp && <Icon as={FaTruckLoading} color={'green'} />}
                    {request.isFragile && <Icon as={TbGlass} color={'orange.700'} />}
                  </HStack>
                  <VStack alignItems={'flex-end'}>
                    {request.loadingType == TRequestLoadingType.FTL && <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'green'}>Puni utovar</Tag>}
                    {request.loadingType == TRequestLoadingType.LTL && <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'red'}>Zbirni tr.</Tag>}
                    {request.weight && <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>{request.weight} kg</Tag>}
                    {request.pallets && <Tag whiteSpace={'nowrap'} size={'sm'} colorScheme={'blue'}>{request.pallets} paleta</Tag>}
                  </VStack>
                </Box>
              </HStack>

              <Divider my={4} />

              <HStack justifyContent={'space-between'}>
                <HStack spacing={5}>
                  <Icon as={FaRegCalendarAlt} />
                  <VStack alignItems={'left'} spacing={0}>
                    <Text fontSize={'sm'}>{displayDate(request.loadingDate)}</Text>
                    <Text color={'gray'} fontSize={'xs'}>Datum utovara</Text>
                  </VStack>
                </HStack>
                <VStack alignItems={'flex-end'} textAlign={'right'}>
                  <Image cursor={'pointer'} src={request?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'48px'} />

                  <Text textAlign={'right'} color={'gray'} fontSize={'xs'}>{request?.vehicles[0]?.name}</Text>
                </VStack>
              </HStack>

              <Divider my={4} />

              <HStack justifyContent={'space-between'}>
                <HStack spacing={5}>
                  {request?.offers?.length > 0 &&
                    <>
                      <Icon as={FaCoins} />
                      <VStack alignItems={'left'} spacing={0}>
                        <Text fontSize={'sm'}>{request.offers[request.offers.length - 1]?.amount}</Text>
                        <Text color={'gray'} fontSize={'xs'}>Posljednja ponuda</Text>
                      </VStack>
                    </>
                  }
                  {request?.offers?.length == 0 && <Text color={'gray'} fontSize={'xs'} p={0} mb={2} textAlign={'center'}>
                    {t('sent_before', { time: request?.createdAtBefore })}
                  </Text>}
                </HStack>
                <Box textAlign={'right'} mt={5}>
                  <Button as={ReachLink} to={`/requests/${request.id}#view-placement`} size={'sm'} colorScheme={'blue'}>Pregledaj</Button>
                </Box>
              </HStack>
            </Box>
          ))
        }
        <Stack alignItems={'center'}>
          <Spinner />
        </Stack>
      </Show>



      <Spacer h={'50px'} />

    </>
  )
};

export default Dashboard;