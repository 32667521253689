import { ICloseFreightRequest, ICompanyCreateRequest, ICreateFreightRequest, IMeta, IMetaRequest, IMetaRequestFreightRequest, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const companiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createCompany: builder.mutation<{ request: IRequest }, ICompanyCreateRequest>({
      query: (data: ICompanyCreateRequest) => ({ url: `/companies`, method: 'POST', body: data }),
      invalidatesTags: ['Company']
    }),
  }),
  overrideExisting: false
})

export const { useCreateCompanyMutation } = companiesApi;
