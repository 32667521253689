import { ICloseFreightOffer, ICreateFreightOffer, IMetaRequestFreightOffer, IOffer } from "../../resources/interfaces";
import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const offersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOffers: builder.query<{ offers: Array<IOffer>, meta: { pagination: IMeta } }, IMetaRequestFreightOffer>({
      query: (meta: IMetaRequestFreightOffer) => ({ url: `/offers`, method: 'GET', params: meta }),
      providesTags: ['Offer']
    }),
    getOffer: builder.query<{ offer: IOffer }, number>({
      query: (id: number) => ({ url: `/offers/${id}`, method: 'GET' }),
      providesTags: ['Offer']
    }),
  }),
  overrideExisting: false
})

export const { useGetOfferQuery, useGetOffersQuery } = offersApi;
