import { TableContainer, Image, Text, Box, Table, Thead, Tr, Th, Tbody, Td, HStack, VStack, Tooltip, Stack, Tag, Button, Badge, Input, Link } from "@chakra-ui/react";
import React, { useState } from "react";
import { CircleFlag } from "react-circle-flags";
import Paginator from "../../components/Common/Paginator";
import { displayDate } from "../../helpers/dateHelper";
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';

import { TRequestState } from "../../resources/interfaces";
import { useGetRequestsQuery } from "../../state/services/requests";
import { Filter } from "../Components/Filter";
import { useGetAdminRequestsQuery } from "../../state/services/admin/requests";
import { FiExternalLink } from "react-icons/fi";

const Requests = () => {
  const [perPage, setPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('')

  const { data: requestsData, isFetching } = useGetAdminRequestsQuery({ per_page: perPage, page: currentPage, query: query }, { refetchOnMountOrArgChange: true, pollingInterval: 5000 });

  return (
    <>
      <Input onChange={(e) => setQuery(e.target.value)} mb={5} w={'sm'} placeholder={'Pretraga...'} />

      <TableContainer borderRadius={'md'} boxShadow={'base'}>
        <Table wordBreak={'break-word'} boxShadow={'base'} variant={'primary'}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Preuzimanje</Th>
              <Th>Dostava</Th>
              <Th>Datum utovara</Th>
              <Th>Kompanija</Th>
              <Th>Broj ponuda</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {requestsData?.requests?.map((request) => (
              <Tr>
                <Td><Badge>{request?.id}</Badge></Td>
                <Td>
                  <HStack spacing={5}>
                    <CircleFlag countryCode={request?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontWeight={'bold'} fontSize={'sm'}>{request.loadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{request.loadingAddress}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td>
                  <HStack spacing={5}>
                    <CircleFlag countryCode={request?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontWeight={'bold'} fontSize={'sm'}>{request.unloadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{request.unloadingAddress}</Text>
                    </VStack>
                  </HStack>
                </Td>

                <Td>
                  <Text fontSize={'sm'}>{displayDate(request.loadingDate)}</Text>
                </Td>
                <Td>

                  <Link target={'_blank'} as={ReachLink} to={`/admin/companies/${request.company.id}`} fontSize={'sm'}>
                    <HStack>
                      <Text>{request?.company?.name}</Text>
                      <FiExternalLink />
                    </HStack>
                  </Link>
                </Td>
                <Td>
                  <Text fontSize={'sm'}>{request?.offers?.length}</Text>
                </Td>
                <Td>
                  {request.state == TRequestState.Created && <Tag size={'sm'} colorScheme={'green'}>Otvoreno</Tag>}
                  {request.state == TRequestState.Closed && <Tag size={'sm'} colorScheme={'red'}>Zatvoreno</Tag>}
                </Td>
                <Td>
                  <Button as={ReachLink} to={`/admin/requests/${request.id}`} size={'sm'} colorScheme={'blue'} variant={'solid'}>Pregledaj</Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <Box py={5} mb={5} textAlign={'left'}>
        <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
      </Box>
    </>
  )
};

export default Requests;