import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IMetaRequestFreightRequest, IRequest, IUser, IUserCreateRequest } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const transportationRequestsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransportationRequests: builder.query<{ requests: Array<IRequest>, meta: { pagination: IMetaRequestFreightRequest } }, IMetaRequest>({
      query: (meta: IMetaRequestFreightRequest) => ({ url: `/transportation/requests`, method: 'GET', params: meta }),
      providesTags: ['Request']
    }),
    getTransportationRequest: builder.query<{ request: IRequest }, number>({
      query: (id: number) => ({ url: `/transportation/requests/${id}`, method: 'GET' }),
      providesTags: ['Request']
    }),
    moreInfoRequest: builder.mutation<{ request: IRequest }, number>({
      query: (data: number) => ({ url: `/transportation/requests/${data}/request_more_info`, method: 'POST', body: data }),
      invalidatesTags: ['Request']
    }),
  }),
  overrideExisting: false
})

export const { useMoreInfoRequestMutation, useGetTransportationRequestsQuery, useGetTransportationRequestQuery } = transportationRequestsApi;
