import React from "react";

const Dashboard = () => {
  return (
    <>
      Dobrodošli!
    </>
  )
};

export default Dashboard;