import { IAdminCompany, ICloseFreightOffer, ICompany, ICompanyUpdateRequest, ICreateFreightOffer, IMetaRequestFreightOffer, IOffer } from "../../../resources/interfaces";
import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IRequest, IUser, IUserCreateRequest } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const adminCompaniesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<{ companies: Array<IAdminCompany>, meta: { pagination: IMeta } }, IMetaRequest>({
      query: (meta: IMetaRequest) => ({ url: `/admin/companies`, method: 'GET', params: meta }),
      providesTags: ['Company']
    }),
    getCompany: builder.query<{ company: IAdminCompany }, number>({
      query: (id: number) => ({ url: `/admin/companies/${id}`, method: 'GET' }),
      providesTags: ['Company']
    }),
    updateCompany: builder.mutation<{ company: IAdminCompany }, FormData | ICompanyUpdateRequest>({
      query: (data: ICompanyUpdateRequest) => {
        const id = data instanceof FormData ? data?.get('id') : data?.id;

        return { url: `/admin/companies/${id}`, method: 'PUT', body: data }
      },
      invalidatesTags: ['Company']
    }),
  }),
  overrideExisting: false
})

export const { useGetCompaniesQuery, useGetCompanyQuery, useUpdateCompanyMutation } = adminCompaniesApi;
