import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const primary = definePartsStyle({
  thead: {
    bg: '#05303f',
    color: 'white',
    tr: {
      th: {

      }
    }
  },
  tbody: {
    tr: {
      _odd: {
        bg: 'white',
      },
      _even: {
        bg: 'gray.50'
      },
      td: {
        borderBottom: 'solid 1px',
        borderBottomColor: 'gray.200'
      }
    }
  }
})

export const tableTheme = defineMultiStyleConfig({
  variants: { primary },
});