import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, AvatarGroup, Stack, Center, Thead, Table, Th, Tr, Tbody, Td, Input, InputGroup, InputLeftElement, TableContainer, Show, Divider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Select, useDisclosure, Tag } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TDepartmentTechnicalName, TOfferState, TRequestLoadingType } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaEllipsisV, FaEye, FaFire, FaInfo, FaInfoCircle, FaRegCalendarAlt, FaSearch, FaTruck, FaTruckMoving } from 'react-icons/fa';
import { useGetRequestsQuery } from '../../state/services/requests';
import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import Paginator from '../../components/Common/Paginator';
import { Controller } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { useGetTransportationOffersQuery } from '../../state/services/transportation/offers';
import { useGetOffersQuery } from '../../state/services/offers';
import { Filter } from '../Components/Filter';
import { useGetAdminOffersQuery } from '../../state/services/admin/offers';


const Offers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('')

  const { data: requestsData } = useGetAdminOffersQuery({ per_page: 7, page: currentPage, query: query }, { refetchOnMountOrArgChange: true, pollingInterval: 5000 });

  return (
    <>
      <Input onChange={(e) => setQuery(e.target.value)} mb={5} w={'sm'} placeholder={'Pretraga...'} />

      <TableContainer borderRadius={'md'} boxShadow={'base'}>
        <Table boxShadow={'base'} variant={'primary'}>
          <Thead>
            <Tr>
              <Th>Preuzimanje</Th>
              <Th>Dostava</Th>
              <Th>Datum polaska</Th>
              <Th>Opcija utovara</Th>
              <Th>Vozilo</Th>
              <Th>Prijevoznik</Th>
            </Tr>
          </Thead>
          <Tbody>
            {requestsData?.offers?.map((offer) => (
              <Tr>
                <Td>
                  <HStack spacing={5}>
                    <CircleFlag countryCode={offer?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontWeight={'bold'} fontSize={'sm'}>{offer.loadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.loadingAddress}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td>
                  <HStack spacing={5}>
                    <CircleFlag countryCode={offer?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontWeight={'bold'} fontSize={'sm'}>{offer.unloadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.unloadingAddress}</Text>
                    </VStack>
                  </HStack>
                </Td>
                <Td>
                  <Text fontSize={'sm'}>{displayDate(offer.loadingDate)}</Text>
                </Td>
                <Td>
                  {offer.loadingType == TRequestLoadingType.FTL && <Tag colorScheme={'green'}>FTL - Puni utovar</Tag>}
                  {offer.loadingType == TRequestLoadingType.LTL && <Tag colorScheme={'red'}>LTL - Zbirni transport</Tag>}
                </Td>
                <Td>
                  <Tooltip label={<Stack>
                    {offer.vehicles.map((v) => <Text>{v.name}</Text>)}
                  </Stack>} placement={'bottom'} hasArrow>
                    <Image cursor={'pointer'} src={offer?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'48px'} />
                  </Tooltip>
                </Td>
                <Td>
                  <HStack spacing={5}>
                    <Avatar size={'sm'} name={offer?.company?.name} src={offer?.company?.logoPath} />
                    <VStack spacing={0} alignItems={'left'}>
                      <Text fontSize={'sm'}>{offer?.company?.name}</Text>
                      <Text color={'gray'} fontSize={'xs'}>prije {offer?.createdAtBefore}.</Text>
                    </VStack>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>



      <Box py={5} mb={5} textAlign={'left'}>
        <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
      </Box>
    </>
  )
};

export default Offers;