import { Box, Image, Tooltip, HStack, Icon, Spacer, Text, VStack, Button, Avatar, AvatarGroup, Stack, Center, Thead, Table, Th, Tr, Tbody, Td, Input, InputGroup, InputLeftElement, TableContainer, Show, Divider, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Select, useDisclosure, Badge, Tag } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckSquare, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TDepartmentTechnicalName, TRequestLoadingType } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { FaArrowLeft, FaArrowRight, FaEllipsisV, FaEye, FaFire, FaInfo, FaInfoCircle, FaRegCalendarAlt, FaSearch, FaTruck, FaTruckMoving } from 'react-icons/fa';
import { useGetRequestsQuery } from '../../state/services/requests';
import { useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import Paginator from '../../components/Common/Paginator';
import { Controller } from 'react-hook-form';
import { displayDate } from '../../helpers/dateHelper';
import { useGetTransportationOffersQuery } from '../../state/services/transportation/offers';
import { Filter } from '../Components/Filter';
import { findByCode } from '../../resources/eu_countries';
import { useTranslation } from 'react-i18next';

const Offers = () => {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state: RootState) => state.user)
  const [currentPage, setCurrentPage] = useState(1);
  const [filterData, setFilterData] = useState({})

  const { data: requestsData } = useGetTransportationOffersQuery({ per_page: 7, page: currentPage, ...filterData });

  const cancelRef = React.useRef();

  const request = requestsData?.offers[0];

  return (
    <>
      <Spacer h={'30px'} />

      <Text mb={5} fontSize={'20px'} fontWeight={'light'}>{t('my_free_vehicles')}</Text>

      <Filter filterData={filterData} setFilterData={setFilterData} setCurrentPage={setCurrentPage} />

      <Show above={'md'}>
        <TableContainer borderRadius={'md'} boxShadow={'base'}>
          <Table boxShadow={'base'} variant={'primary'}>
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th>{t('pickup')}</Th>
                <Th>{t('delivery')}</Th>
                <Th>{t('departure_date')}</Th>
                <Th>{t('loading_option')}</Th>
                <Th>{t('vehicle')}</Th>
                <Th>{t('offer_date')}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {requestsData?.offers?.map((request) => (
                <Tr>
                  <Td><Badge>{request.id}</Badge></Td>
                  <Td>
                    <Tooltip hasArrow label={findByCode(request?.loadingCountry)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={request?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{request.loadingCity}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{request.loadingAddress}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Tooltip hasArrow label={findByCode(request?.unloadingCountry)?.label}>
                      <HStack spacing={5}>
                        <CircleFlag countryCode={request?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                        <VStack spacing={0} alignItems={'left'}>
                          <Text fontWeight={'bold'} fontSize={'sm'}>{request.unloadingCity}</Text>
                          <Text color={'gray'} fontSize={'xs'}>{request.unloadingAddress}</Text>
                        </VStack>
                      </HStack>
                    </Tooltip>
                  </Td>
                  <Td>
                    <Text fontSize={'sm'}>{displayDate(request.loadingDate)}</Text>
                  </Td>
                  <Td>
                    {request.loadingType == TRequestLoadingType.FTL && <Tag colorScheme={'green'}>{t('ftl')}</Tag>}
                    {request.loadingType == TRequestLoadingType.LTL && <Tag colorScheme={'red'}>{t('ltl')}</Tag>}
                  </Td>
                  <Td>
                    <Tooltip label={<Stack>
                      {request.vehicles.map((v) => <Text>{v.name}</Text>)}
                    </Stack>} placement={'bottom'} hasArrow>
                      <Image cursor={'pointer'} src={request?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'48px'} />
                    </Tooltip>
                  </Td>
                  <Td>
                    <Text fontSize={'sm'}>{displayDate(request.createdAt)}</Text>
                  </Td>
                  <Td>
                    <Button leftIcon={<Icon as={FaEye} />} as={ReachLink} to={`/app/offers/${request.id}`} size={'sm'} colorScheme={'blue'} variant={'solid'}>{t('view')}</Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Show>

      <Show below={'md'}>
        {
          requestsData?.offers?.map((offer) => (
            <Box mb={5} border={'solid 1px'} borderColor={'gray.300'} bg={'white'} py={5} px={6} borderRadius={'md'} boxShadow={'md'}>
              <HStack spacing={6}>
                <VStack spacing={4}>
                  <CircleFlag countryCode={offer?.loadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                  <Icon as={FaEllipsisV} />
                  <CircleFlag countryCode={offer?.unloadingCountry?.toLowerCase() ?? 'ba'} width={'16px'} />
                </VStack>
                <VStack alignItems={'left'}>
                  <HStack spacing={5}>
                    <VStack spacing={0} alignItems={'left'}>
                      <Text>{offer.loadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.loadingAddress}</Text>
                    </VStack>
                  </HStack>
                  <Spacer h={'20px'} />
                  <HStack spacing={5}>
                    <VStack spacing={0} alignItems={'left'}>
                      <Text>{offer.unloadingCity}</Text>
                      <Text color={'gray'} fontSize={'xs'}>{offer.unloadingAddress}</Text>
                    </VStack>
                  </HStack>
                </VStack>
              </HStack>

              <Divider my={4} />

              <HStack justifyContent={'space-between'}>
                <HStack spacing={5}>
                  <Icon as={FaRegCalendarAlt} />
                  <VStack alignItems={'left'} spacing={0}>
                    <Text fontSize={'sm'}>{displayDate(offer.loadingDate)}</Text>
                    <Text color={'gray'} fontSize={'xs'}>{t('departure_date')}</Text>
                  </VStack>
                </HStack>
                <Tooltip label={<Stack>
                  {offer.vehicles.map((v) => <Text>{v.name}</Text>)}
                </Stack>} placement={'bottom'} hasArrow>
                  <Image cursor={'pointer'} src={offer?.vehicles[0]?.imagePath} mixBlendMode={'multiply'} w={'64px'} />
                </Tooltip>
              </HStack>

              <Spacer h={'20px'} />

              <HStack justifyContent={'space-between'}>
                <Box>
                  {offer.loadingType == TRequestLoadingType.FTL && <Tag size={'sm'} colorScheme={'green'}>{t('ftl')}</Tag>}
                  {offer.loadingType == TRequestLoadingType.LTL && <Tag size={'sm'} colorScheme={'red'}>{t('ltl')}</Tag>}
                </Box>
                <Box textAlign={'right'} mt={5}>
                  <Button as={ReachLink} to={`/app/offers/${offer.id}#view-placement`} size={'sm'} colorScheme={'blue'}>{t('view')}</Button>
                </Box>
              </HStack>
            </Box>
          ))
        }
      </Show>

      <Box py={5} mb={5} textAlign={'left'}>
        <Paginator meta={requestsData?.meta?.pagination} onPageChange={(page: number) => setCurrentPage(page)} />
      </Box>
    </>
  )
};

export default Offers;