import { ICloseFreightOffer, ICreateFreightOffer, IMetaRequestFreightOffer, IMetaRequestFreightRequest, IOffer } from "../../../resources/interfaces";
import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IRequest, IUser, IUserCreateRequest } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const transportationOffersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTransportationOffers: builder.query<{ offers: Array<IOffer>, meta: { pagination: IMeta } }, IMetaRequestFreightOffer>({
      query: (meta: IMetaRequestFreightOffer) => ({ url: `/transportation/offers`, method: 'GET', params: meta }),
      providesTags: ['Offer']
    }),
    getTransportationOffer: builder.query<{ offer: IOffer }, number>({
      query: (id: number) => ({ url: `/transportation/offers/${id}`, method: 'GET' }),
      providesTags: ['Offer']
    }),
    createTransportationOffer: builder.mutation<{ offer: IOffer }, ICreateFreightOffer>({
      query: (data: ICreateFreightOffer) => ({ url: `/transportation/offers`, method: 'POST', body: data }),
      invalidatesTags: ['Offer']
    }),
    closeTransportationOffer: builder.mutation<{ offer: IOffer }, ICloseFreightOffer>({
      query: (data: ICloseFreightOffer) => ({ url: `/transportation/offers/${data.id}/close`, method: 'POST', body: data }),
      invalidatesTags: ['Offer']
    }),
  }),
  overrideExisting: false
})

export const { useGetTransportationOfferQuery, useGetTransportationOffersQuery, useCloseTransportationOfferMutation, useCreateTransportationOfferMutation } = transportationOffersApi;
