export interface IUserLoginRequest {
  email: string;
  password: string;
}

export interface IUserLoginResponse {
  token: string;
}

export interface IUser {
  id?: number;
  uuid?: string;
  username?: string;
  email: string;
  isAdmin: boolean;
  department?: IDepartment;
  name?: string;
  terminal?: string;
  company?: ICompany;
}

export interface ICompany {
  id?: number;
  name: string;
  logoPath: string;
  businessType: TBusinessType;
  phone?: string;
  rating?: number;
  notificationsCount?: number;
  city?: string;
  address?: string;
}

export interface IAdminCompany {
  id?: number;
  name: string;
  logoPath: string;
  businessType: TBusinessType;
  phone?: string;
  rating?: number;
  notificationsCount?: number;
  email?: string;
  address?: string;
  city?: string;
  country?: string;
  createdAt?: string;
  idNumber?: string;
  vatNumber?: string;
  users?: Array<IUser>;
  approved?: boolean;
}

export interface ICompanyUpdateRequest {
  id?: number;
  name: string;
  logo: any;
  businessType: TBusinessType;
  phone?: string;
  rating?: number;
  email?: string;
  address?: string;
  city?: string;
  country?: string;
  idNumber?: string;
  vatNumber?: string;
}

export interface ICompanyCreateRequest {
  name: string;
  businessType: TBusinessType;
  phone?: string;
  email?: string;
  address?: string;
  city?: string;
  country?: string;
  idNumber?: string;
  vatNumber?: string;
}

export interface IGeoplace {
  name: string;
  toponomyName: string;
}

export interface IGeoplaceSearchRequest {
  q: string;
  country_code: string;
}

export interface INotification {
  id?: number;
  resource?: any;
  title: string;
  description: string;
  hyperlink?: string;
  notificationType?: TNotificationType;
  isRead?: boolean;
  createdAtBefore?: string;
}

export interface IRequest {
  id?: number;
  state: TRequestState;
  goodsDescription: string;

  dimensionsLength: string;
  dimensionsWidth: string;
  dimensionsHeight: string;

  isAdr: boolean;
  isFragile: boolean;
  isFrigo: boolean;
  isRamp: boolean;

  note: string;
  packages: string;
  pallets: string;
  space: string;
  weight: string;

  loadingAddress: string;
  loadingCity: string;
  loadingCountry: string;
  loadingDate: string;
  loadingType: string;

  unloadingAddress: string;
  unloadingCity: string;
  unloadingCountry: string;
  unloadingDate: string;

  createdAtBefore?: string;

  vehicles: Array<IVehicle>;
  offers: Array<IRequestOffer>;
  company: ICompany;

  offer?: IRequestOffer;
}

export interface IOffer {
  id?: number;
  state: TOfferState;
  isAdr: boolean;
  isFrigo: boolean;
  isRamp: boolean;

  note: string;
  space: string;
  packages: string;
  pallets: string;

  loadingAddress: string;
  loadingCity: string;
  loadingCountry: string;
  loadingDate: string;
  loadingType: string;

  unloadingAddress: string;
  unloadingCity: string;
  unloadingCountry: string;

  vehicles: Array<IVehicle>;
  company?: ICompany;
  createdAt: string;
  createdAtBefore: string;
}

export interface IRequestOffer {
  id?: number;
  amount: number;
  createdAt: string;
  createdAtBefore?: string;
  company: ICompany;
  accepted: boolean;
  rating?: number;
}

export interface IUpdateRequestOffer {
  id?: number;
  requestId: number;
  accepted: boolean;
}

export interface ICloseFreightRequest {
  id: number;
  request_offer_id: number;
}

export interface ICloseFreightOffer {
  id: number;
}

export interface ICreateFreightRequestOffer {
  requestId?: string;
  amount: number;
  rating: number;
}

export interface IRateRequestOffer {
  id?: number;
  requestId?: number;
  rating: number;
}

export interface ICreateFreightRequest {
  id?: number;
  state?: TRequestState;
  goods_description: string;

  dimensions: string;
  is_adr: boolean;
  is_fragile: boolean;
  is_frigo: boolean;
  is_ramp: boolean;

  note: string;
  packages: string;
  pallets: string;
  space: string;
  weight: string;

  loading_address: string;
  loading_city: string;
  loading_country: string;
  loading_date: string;
  loading_type: string;
  loading_phone: string;

  unloading_address: string;
  unloading_city: string;
  unloading_country: string;
  unloading_phone: string;

  vehicles: Array<number>;
}

export interface ICreateFreightOffer {
  is_adr: boolean;
  is_frigo: boolean;
  is_ramp: boolean;

  note: string;
  packages: string;
  pallets: string;
  space: string;

  loading_address: string;
  loading_city: string;
  loading_country: string;
  loading_date: string;
  loading_type: string;

  unloading_address: string;
  unloading_city: string;
  unloading_country: string;

  vehicles: Array<number>;
}

export interface IVehicle {
  id?: number;
  name?: string;
  description?: string;
  imagePath?: string;
}

export interface ISetting {
  id?: number;
  name: string;
  value: string;
};

export interface IClient {
  id?: number;
  name: string;
  cargoId?: string;
  email?: string;
  centerNumber: string;
  commercialist: string;
  address?: string;
  zipCode?: string;
  city?: string;
  sdsAmount?: string;
  dfs_1: number;
  dfs_2: number;
  createdAt: string;
}

export interface IClientUpdateRequest {
  id?: number;
  sdsAmount: string;
  name: string;
  email: string;
  dfs_1: string;
  dfs_2: string;
}

export interface IOrder {
  id: number;
  state: TOrderState;
  uuid: string;
  wexId: string;
  sapId: string;
  customerName: string;
  consigneeName: string;
  terminal: string;
  customerTerminal: string;
  note: string;
  status: string;
  collectedAmount: number;
  wexCreatedAt: string;
  modifiedByUser: IUser;
  createdAt: string;
  deletedAt: string;
  stateHistories: Array<IStateHistory>;
}

export interface IOrderSpecification {
  id: number;
  sapId: string;
  customerName: string;
  customerTerminal: string;
  user: IUser;
  createdAt: string;
  deletedAt: string;
  note?: string;
  status?: string;
}

export interface IActivity {
  id: number;
  description: string;
  meta: string;
  user: IUser;
  createdAt: string;
}


export interface IOrderUpdateRequest {
  id?: string;
  state?: string;
  collectedAmount?: string;
  note?: string;
  terminal?: string;
  customerTerminal?: string;
}

export interface IInvoiceUpdateRequest {
  id: number;
  shipmentsAmount: number;
  sdsAmount: number;
  other: number;
}

export interface IOrderCollectRequest {
  id: number;
  collectedAmount: number;
}

export interface IChartData {
  departments: Array<IChartDataSet>;
  centers: Array<IChartDataSet>;
  yearly: Array<IChartDataSet>;
}

export interface IChartDataSet {
  title: string;
  number: number;
}

export interface IOrderDeleteRequest {
  id: number;
  note: string;
}

export interface IOrderSpecificationCreateRequest {
  sapId: string;
  courier?: string;
}

export interface IOrderSpecificationConfirmRequest {
  id: number;
  status: string;
  comment?: string;
}

export interface IInvoiceUpdatePaidRequest {
  id: number;
  paid: boolean;
}

export interface IDepartmentRecipient {
  departmentId: number;
  email: string;
}

export interface IDepartment {
  id?: number;
  name?: string;
  icon: string;
  priority: number;
  technicalName: TDepartmentTechnicalName;
  reasons: Array<IReason>;
  departmentRecipients: Array<IDepartmentRecipient>;
}

export enum TDepartmentTechnicalName {
  CustomerService = 'customer_service',
  Finance = 'finance'
}

export interface ICallLog {
  id?: number;
  uuid?: string;
  callerInfo?: string;
  callerPhone?: string;
  comment?: string;
  department: IDepartment;
  reason: IReason;
  commercial?: ICommercial;
  center?: ICenter;
}

export interface ICallLogCreateRequest {
  callerInfo?: string;
  callerPhone?: string;
  comment?: string;
  departmentId: number;
  reasonId: number;
  commercialId?: number;
  centerId?: number;
  messageChannel?: string;
}

export interface IDepartmentRecipientCreateRequest {
  departmentId: number;
  email: string;
}

export interface IUserCreateRequest {
  id?: number;
  email?: string;
  username?: string;
  password?: string;
  name?: string;
  companyId?: number;
}

export interface ICommercialistCreateRequest {
  id?: number;
  name?: string;
}

export interface ICourierCreateRequest {
  name?: string;
}

export interface IReportCreateRequest {
  dateFrom: string;
  dateTo: string;
  departmentId: number;
};

export interface ICommercial {
  id?: number;
  name?: string;
  priority: number;
  technicalName: string;
}

export interface ICourier {
  id?: number;
  name?: string;
}

export interface ICenter {
  id?: number;
  name?: string;
  priority: number;
  technicalName: string;
}

export interface IReason {
  id?: number;
  name?: string;
  technicalName: string;
  priority: number;
  reason: IReason;
  department: IDepartment;
}

export class IMeta {
  page: number;
  perPage: number;
  totalAmount?: number;
}

export class IMetaRequest {
  page: number;
  perPage?: number;
  per_page?: number;
  query?: string;
}

export class IMetaRequestFreightRequest {
  page: number;
  perPage?: number;
  per_page?: number;
  query?: string;
  loading_nfo?: string;
  unloading_info?: string;
  loading_date?: string;
  vehicles?: string;
}

export class IMetaRequestFreightOffer {
  page: number;
  perPage?: number;
  per_page?: number;
  query?: string;
  loading_nfo?: string;
  unloading_info?: string;
  loading_date?: string;
  vehicles?: string;
}

export class IMetaRequestOrders {
  page: number;
  perPage: number;
  per_page?: number;
  query?: string;
  state?: TOrderState;
  current_terminal?: boolean;
  current_customer_terminal?: boolean;
}

export class IMetaRequestOrderSpecifications {
  page: number;
  perPage: number;
  per_page?: number;
  query?: string;
  state?: TOrderSpecificationState;
  current_customer_terminal?: boolean;
}

export class IMetaRequestActivities {
  page: number;
  perPage: number;
  per_page?: number;
  query?: string;
}

export interface IStateHistory {
  state: string;
  previousState: string;
  createdAt: string;
  user: IUser;
}

export class IInvoiceBook {
  id: number;
  filename: string;
  state: TInvoiceBookState;
  shipmentsCount: number;
  invoicesCount: number;
}

export class IInvoiceBooksNotify {
  month?: number;
  year?: number;
}

export enum TInvoiceBookState {
  Pending = 'pending',
  Started = 'started',
  ProcessingShipments = 'processing_shipments',
  ProcessingInvoices = 'processing_invoices',
  AssigningNumbers = 'assigning_numbers',
  Completed = 'completed',
  Failed = 'failed',
}

export enum TOrderState {
  Pending = 'pending',
  Collected = 'collected',
  Dispatched = 'dispatched',
  Accepted = 'accepted',
  Delivery = 'delivery',
  Confirmed = 'confirmed'
}

export enum TOrderSpecificationState {
  Pending = 'pending',
  Confirmed = 'confirmed'
}

export enum TRequestLoadingType {
  FTL = 'ftl',
  LTL = 'ltl'
}

export enum TBusinessType {
  Standard = 'standard',
  Transportation = 'transportation',
  Forwarder = 'forwarder'
}

export enum TRequestState {
  Created = 'created',
  Closed = 'closed'
}

export enum TOfferState {
  Created = 'created',
  Closed = 'closed'
}

export enum TNotificationType {
  Generic = 'generic',
  NewRequests = 'new_requests',
  NewOffer = 'new_offer',
  AcceptedOffer = 'accepted_offer',
  ClosedRequestNotAccepted = 'closed_request_not_accepted',
  RateTransportationCompany = 'rate_transportation_company'
}