import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IMetaRequestFreightRequest, IRequest, IUser, IUserCreateRequest } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const adminRequestsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAdminRequests: builder.query<{ requests: Array<IRequest>, meta: { pagination: IMeta } }, IMetaRequest>({
      query: (meta: IMetaRequest) => ({ url: `/admin/requests`, method: 'GET', params: meta }),
      providesTags: ['Request']
    }),
    getAdminRequest: builder.query<{ request: IRequest }, number>({
      query: (id: number) => ({ url: `/admin/requests/${id}`, method: 'GET' }),
      providesTags: ['Request']
    }),
    updateRequest: builder.mutation<{ request: IRequest }, ICreateFreightRequest>({
      query: (data: ICreateFreightRequest) => ({ url: `/admin/requests/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['Request']
    }),
  }),
  overrideExisting: false
})

export const { useGetAdminRequestsQuery, useGetAdminRequestQuery, useUpdateRequestMutation } = adminRequestsApi;
