import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "../../resources/interfaces";
import { RootState, store } from "../store";

type UserState = {
  currentUser: IUser | null
};

const slice = createSlice({
  name: 'users',
  initialState: {
    currentUser: null,
  } as UserState,
  reducers: {
    setCurrentUser: (state, { payload }: PayloadAction<IUser>) => {
      state.currentUser = payload;
    }
  }
});

export const { setCurrentUser } = slice.actions;
export default slice.reducer;

export const currentUser = (state: RootState) => state.user.currentUser;
