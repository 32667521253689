import React, { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Image,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Divider,
  Badge,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
  FiShield,
  FiLogIn,
  FiLayers,
  FiUserPlus,
  FiFileText,
  FiPrinter,
  FiShuffle,
  FiPlusCircle,
  FiMinusCircle,
  FiTruck,
  FiCheckCircle,
  FiHardDrive,
  FiSkipBack,
} from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { redirect, useLocation, useNavigate, Link as ReachLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { logoutSession } from '../../../helpers/sessionHelper';
import { current } from '@reduxjs/toolkit';
import { TDepartmentTechnicalName } from '../../../resources/interfaces';
import { FaFileInvoice, FaFolderPlus, FaUser } from 'react-icons/fa'
import { GrMoney } from 'react-icons/gr';

export default function SidebarWithHeader({
  children,
}: {
  children: ReactNode;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg={'white'}>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 80 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const { currentUser } = useSelector((state: RootState) => state.user)

  return (
    <Box
      transition="3s ease"
      bg={'#1a1c1e'}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 80 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" mb="5" justifyContent="space-between">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          <Image w={'full'} src={'/images/logo-white.png'} />
        </Text>
        <CloseButton color={'white'} display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      <Box>
        <Box my={2}>

          <NavItem color={'white'} to={'/admin'} icon={FiHome}>
            Početna
          </NavItem>
          <NavItem color={'white'} to={'/dashboard'} icon={FiSkipBack}>
            Povratak u aplikaciju
          </NavItem>
        </Box>

        <Divider borderColor={'gray.600'} my={2} />

        <Box my={5}>
          <NavItem to={'/admin/requests'} icon={FiCheckCircle}>
            Zahtjevi za transport
          </NavItem>
          <NavItem to={'/admin/offers'} icon={FiTruck}>
            Slobodna vozila za transport
          </NavItem>
        </Box>

        <Divider borderColor={'gray.600'} my={2} />

        <Box my={5}>
          <NavItem to={'/admin/companies'} icon={FiHardDrive}>
            <HStack justifyContent={'space-between'}>
              <Text>Kompanije</Text>
            </HStack>
          </NavItem>
          <NavItem to={'/admin/invoices'} icon={FaFileInvoice}>
            Fakture
          </NavItem>
        </Box>

        <Divider borderColor={'gray.600'} my={2} />

        <NavItem to={'/admin/settings'} icon={FiSettings}>
          Postavke
        </NavItem>
      </Box>
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  to: string;
  icon: IconType;
  children: any;
}
const NavItem = ({ to, icon, children, ...rest }: NavItemProps) => {
  const location = useLocation();

  return (
    <Link as={ReachLink} to={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        px="4"
        py={3}
        mx="4"
        mb={1}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bg={location.pathname === to ? 'gray.700' : 'initial'}
        color={location.pathname === to ? 'white' : 'white'}
        _hover={{
          bg: 'gray.700',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            color={'white'}
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { currentUser } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch();

  const logout = () => {
    logoutSession(dispatch)
  };
  return (
    <Flex
      ml={{ base: 0, md: 80 }}
      px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: '' }}
      {...rest}>
      <Box display={{ base: 'none', md: 'flex' }}>
        <Text color={'grey'}>Dobrodošli, {currentUser?.username}.</Text>
        <Image ml={3} w={'24px'} h={'24px'} src={'/images/0472.png'} />
      </Box>
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text
        display={{ base: 'flex', md: 'none' }}
        fontSize="2xl"
        fontFamily="monospace"
        fontWeight="bold">
        <Image w={'150px'} src={'/images/logo.png'} />
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}>
              <HStack spacing={10}>
                <Icon as={FaUser} />
                <VStack
                  display={{ base: 'none', md: 'flex' }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2">
                  <Text fontSize="sm">{currentUser?.username}</Text>
                  <Text fontSize="xs" color="gray.600">
                    Administrator
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}>
              <MenuItem onClick={logout}>Odjava</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};