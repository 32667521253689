import React, { useEffect } from "react";
import { Box, Center, Link, Text, Image, VStack, Input, Button, ScaleFade, Alert, AlertIcon, HStack, InputGroup, InputRightElement, PinInput, PinInputField, InputLeftElement } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useForgotPasswordMutation, useGetAccessTokenMutation, useResetPasswordMutation, useVerifyForgotPasswordMutation } from "../../state/services/auth";
import { setCredentials } from "../../state/slices/authSlice";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { RootState } from "../../state/store";
import { Navigate } from "react-router-dom";
import { loginSession } from "../../helpers/sessionHelper";
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { FaArrowLeft, FaLock, FaUser } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

const ResetPassword = () => {
  const location = useLocation();
  const { token } = useParams();
  const { t } = useTranslation();

  const { register, handleSubmit } = useForm();
  const [reset, { isError, isLoading, isSuccess }] = useResetPasswordMutation();

  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  const onSubmit = data => {
    data.token = token;

    reset(data);
  };

  return (
    <>
      <Center pt={10}>

      </Center>

      <Box mt={'10vh'}>

        <Center>
          <VStack bg={'white'} p={10} borderRadius={'lg'} boxShadow={'lg'}>

            <Image w={'220px'} mb={10} src={'/images/logo.png'} />

            <Text fontSize={'md'} color={'#2b343f'}>{t('enter_new_password')}</Text>
            <Box w={'100%'}>

              {isSuccess &&
                <ScaleFade initialScale={0.9} in={isSuccess}>
                  <Alert mt={5} fontSize={'sm'} status='success'>
                    <AlertIcon />
                    <VStack>
                      <Text>{t('password_reset_success')}</Text>
                    </VStack>
                  </Alert>

                  <Button mt={3} size={'md'} w={'full'} as={ReachLink} to={'/login'} leftIcon={<FaUser />} colorScheme={'blue'}>{t('login')}</Button>

                  <Button mt={3} size={'md'} w={'full'} as={ReachLink} to={'/'} leftIcon={<FaArrowLeft />}>{t('back')}</Button>

                </ScaleFade>}

              {!isSuccess &&
                <Box mt={5}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mt={5} w={'full'}>
                      <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Šifra</Text>
                      <InputGroup size='md'>
                        <InputLeftElement
                          pointerEvents='none'
                          children={<FaLock color='gray.300' />}
                        />
                        <Input
                          {...register('password', { required: true, minLength: 6 })}
                          pr='4.5rem'
                          type={show ? 'text' : 'password'}
                          autoComplete={'new-password'}
                          placeholder='Nova šifra'
                        />
                        <InputRightElement width='4.5rem'>
                          <Button h='1.75rem' mr={2} size='sm' onClick={handleClick}>
                            {show ? 'Sakrij' : 'Prikaži'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                    </Box>
                    <Text textAlign={'center'} mt={3} fontSize={'sm'} color={'gray.500'}>Minimalna dužina šifre je 6 znakova.</Text>

                    <Button isDisabled={isLoading} type='submit' size={'md'} w={'100%'} mt={'8'} colorScheme={'blue'}>Sačuvaj</Button>
                    <Button mt={3} size={'md'} w={'full'} as={ReachLink} to={'/login'} leftIcon={<FaArrowLeft />}>Nazad</Button>

                  </form>

                  <Center>
                    <HStack mt={5} fontSize={'xs'}>
                      <Link href={'/privacy'}>Politika privatnosti</Link>
                      <Link href={'/terms'} ml={5}>Uslovi korištenja</Link>
                    </HStack>
                  </Center>
                </Box>
              }
            </Box>
          </VStack>
        </Center>
      </Box>
    </>
  )
};

export default ResetPassword;