import { HStack, Checkbox, Text, Box, Image, Icon } from "@chakra-ui/react";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FaTruckLoading, FaFire, FaSnowflake } from "react-icons/fa";
import { GiShatteredGlass } from "react-icons/gi";
import { IVehicle } from "../../../resources/interfaces";

interface IProps {
  color: string;
  icon: any;
  name: string;
  technicalName: string;
  description: string;
  control: any;
  defaultValue?: boolean;
};


const Service = ({ name, technicalName, description, icon, color, control, defaultValue = false }: IProps) => {
  return (
    <>
      <Controller
        control={control}
        name={technicalName}
        defaultValue={defaultValue}
        render={({
          field: { onChange, onBlur, value, ref },
          fieldState: { error }
        }) => (
          <Box bg={value ? color + '.100' : 'white'} cursor={'pointer'} onClick={() => { onChange(!value); }} mt={3} boxShadow={'base'} mb={3} p={5} border={'solid 1px'} borderColor={value ? color + '.500' : 'gray.300'} borderRadius={'md'}>
            <HStack justifyContent={'space-between'} spacing={5}>
              <Box w={'25%'}>
                <Icon w={'100%'} fontSize={'xx-large'} color={color + '.500'} as={icon} />
              </Box>
              <Box w={'full'}>
                <Text color={value ? color + '.500' : 'black'} fontWeight={value ? 'bold' : 'light'} fontSize={'lg'}>{name}</Text>
                <Text color={'gray.500'} fontSize={'xs'}>{description}</Text>
              </Box>

              <Checkbox ref={ref} onBlur={onBlur} onChange={() => onChange(value)} isChecked={value} size={'lg'} colorScheme={color}></Checkbox>
            </HStack>
          </Box>
        )} />
    </>
  );
};

export default Service;