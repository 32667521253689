import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../../store";
import { baseQueryWithOptions } from "./baseQuery";

export const baseApi = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithOptions,
  tagTypes: ['User', 'Setting', 'Company', 'Vehicle', 'Request', 'RequestOffer', 'Offer', 'Notification', 'Geoplace'],
  endpoints: () => ({})
});
