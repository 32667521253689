import { IMeta, IUser, IUserCreateRequest } from "../../../resources/interfaces";
import { baseApi } from "../base/base";

export const adminUsersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<{ user: IUser }, IUserCreateRequest>({
      query: (data: IUserCreateRequest) => ({ url: `/admin/users`, method: 'POST', body: data }),
      invalidatesTags: ['User', 'Company']
    }),
    updateUser: builder.mutation<{ user: IUser }, IUserCreateRequest>({
      query: (data: IUserCreateRequest) => ({ url: `/admin/users/${data.id}`, method: 'PATCH', body: data }),
      invalidatesTags: ['User', 'Company']
    }),
    deleteUser: builder.mutation<{ user: IUser }, number>({
      query: (id: number) => ({ url: `/admin/users/${id}`, method: 'DELETE' }),
      invalidatesTags: ['User', 'Company']
    }),
  }),
  overrideExisting: false
})

export const { useDeleteUserMutation, useUpdateUserMutation, useCreateUserMutation } = adminUsersApi;
