import { Box, Image, HStack, Icon, Spacer, Text, VStack, Button, Avatar, Link, Stack, Flex, Tag, TagLabel, TagLeftIcon, Table, Tbody, Td, Tr, Divider, IconButton, Spinner, Center, Skeleton, SkeletonCircle, SkeletonText, Show, useDisclosure, AvatarBadge, Tab, TabList, TabPanel, TabPanels, Tabs, Textarea, Input, InputGroup, InputRightAddon, Select as ChakraSelect, useToast, Badge, TableContainer, Th, Thead, Tooltip } from '@chakra-ui/react'
import * as React from 'react'
import { FiCheckCircle, FiCheckSquare, FiChevronDown, FiFileText, FiPhoneCall, FiPlusCircle, FiSidebar, FiStar, FiThumbsDown, FiThumbsUp, FiTrash, FiTruck, FiUser } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { TBusinessType, TDepartmentTechnicalName, TRequestLoadingType, TRequestState } from '../../resources/interfaces';
import { RootState } from '../../state/store';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaCheckCircle, FaCircle, FaFire, FaFlag, FaPhone, FaSnowflake, FaTimesCircle, FaTruck, FaTruckLoading, FaViber } from 'react-icons/fa';
import { TbGlass } from 'react-icons/tb';
import { redirect, useLocation, useNavigate, Link as ReachLink, useParams } from 'react-router-dom';
import { useCloseRequestMutation, useCreateRequestMutation, useGetRequestQuery } from '../../state/services/requests';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { useEffect, useRef } from 'react';
import { Select } from 'chakra-react-select';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form';
import { displayDate, todayDate, valueDate } from '../../helpers/dateHelper';
import { BsFillStarFill, BsStar } from 'react-icons/bs';
import CreateRequestOffer from '../TransportationCompany/Request/CreateRequestOffer';
import TransportationRequestOffer from '../TransportationCompany/Request/RequestOffer';
import RequestInformation from '../Request/RequestInformation';
import CountrySelect from '../../components/Form/CountrySelect';
import { GiShatteredGlass } from 'react-icons/gi';
import Service from '../StandardCompany/CreateRequest/Service';
import Vehicle from '../StandardCompany/CreateRequest/Vehicle';
import { useGetVehiclesQuery } from '../../state/services/vehicles';
import { useGetAdminRequestQuery, useUpdateRequestMutation } from '../../state/services/admin/requests';
import { useDeleteRequestOfferMutation, useUpdateRequestOfferMutation } from '../../state/services/admin/request_offers';


const Request = () => {
  let { id } = useParams();
  const closeRequestDisclosure = useDisclosure();
  const { register, handleSubmit, control, getValues } = useForm();
  const toast = useToast();

  const { data: vehiclesData } = useGetVehiclesQuery();
  const { data, isLoading, isSuccess } = useGetAdminRequestQuery(parseInt(id), { refetchOnMountOrArgChange: true });
  const [updateRequest, { isError, isSuccess: isSuccessUpdate }] = useUpdateRequestMutation();
  const [deleteRequestOffer] = useDeleteRequestOfferMutation();
  const [updateRequestOffer] = useUpdateRequestOfferMutation();

  const request = data?.request;

  const getData = () => {
    const data = getValues();

    data.id = request?.id;
    data.vehicles = Object.keys(data.vehicles).filter((key) => data.vehicles[key])?.map((k) => k.replace('vehicle_id_', ''));
    data.loading_country = data.loading_country.value;
    data.unloading_country = data.unloading_country.value;
    data.loading_type = data.loading_type.value;

    return data;
  }

  const onSubmit = () => {
    updateRequest(getData());
  }

  useEffect(() => {
    if (isSuccessUpdate) {
      toast({
        title: 'Uspješno!',
        description: "Uspješno ste sačuvali izmjene zahtjeva.",
        status: 'success',
        duration: 5000,
        position: 'bottom-right',
        isClosable: true,
      })
    }
  }, [isSuccessUpdate]);

  return (
    <>
      {isLoading && <>
        <Center>
          <Spinner />
        </Center>
      </>}

      {
        isSuccess && <>

          <HStack justifyContent={'space-between'} id={'view-placement'}>
            <Button size={'sm'} as={ReachLink} to={'/admin/requests'} leftIcon={<FaArrowLeft />}>Nazad</Button>
            <HStack>
              <Button onClick={handleSubmit(onSubmit)} colorScheme={'green'} leftIcon={<FaCheckCircle />} size={'sm'}>Spremi izmjene</Button>

            </HStack>
          </HStack>
          <Spacer h={'20px'} />

          <Tabs>
            <TabList>
              <Tab>Informacije</Tab>
              <Tab>Ponude</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <form onSubmit={handleSubmit(onSubmit)}>

                  <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
                    <HStack spacing={0} justifyContent={'space-between'} flexDirection={['column', 'row']} alignItems={['center', 'flex-end']}>
                      <Box>
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Preuzimanje</Text>
                        <CountrySelect defaultValue={request?.loadingCountry} control={control} name={'loading_country'} />
                        <Spacer h={'10px'} />
                        <HStack>
                          <Input {...register('loading_city', { required: true })} placeholder={'Grad'} defaultValue={request?.loadingCity}></Input>
                          <Input {...register('loading_address', { required: true })} placeholder={'Adresa'} defaultValue={request?.loadingAddress}></Input>
                        </HStack>
                      </Box>

                      <Box pt={[3, 0]}>
                        <Show above={'md'}>
                          <Icon fontSize={'20px'} as={FaArrowRight} mb={'5px'} />
                        </Show>
                        <Show below={'md'}>
                          <Icon fontSize={'20px'} as={FaArrowDown} mb={'5px'} />
                        </Show>
                      </Box>

                      <Box>
                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dostava</Text>

                        <CountrySelect defaultValue={request?.unloadingCountry} control={control} name={'unloading_country'} />
                        <Spacer h={'10px'} />
                        <HStack>
                          <Input {...register('unloading_city', { required: true })} placeholder={'Grad'} defaultValue={request?.unloadingCity}></Input>
                          <Input {...register('unloading_address', { required: true })} placeholder={'Adresa'} defaultValue={request?.unloadingAddress}></Input>
                        </HStack>
                      </Box>
                    </HStack>
                  </Box>

                  <Box w={'full'} mb={5} bg={'white'} p={5} borderRadius={'md'} boxShadow={'base'}>
                    <HStack flexDirection={['column', 'row']} alignItems={'flex-start'} spacing={[0, 10]}>
                      <Box w={'full'}>

                        <Box mb={5} >
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Status zahtjeva</Text>
                          <ChakraSelect  {...register('state')} defaultValue={request?.state}>
                            <option value={'created'}>Otvoreno</option>
                            <option value={'closed'}>Zatvoreno</option>
                          </ChakraSelect>
                        </Box>

                        <Box mb={5} >
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Vrsta robe/tereta</Text>
                          <Input defaultValue={request?.goodsDescription} {...register('goods_description', { required: true, maxLength: 250 })} placeholder={'Unesite opis robe/tereta...'}></Input>
                        </Box>

                        <Box mb={5} >
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Opcija utovara</Text>
                          <Controller
                            control={control}
                            name="loading_type"
                            defaultValue={{ value: request?.loadingType, label: request?.loadingType.toUpperCase() }}
                            render={({
                              field: { onChange, onBlur, value, name, ref },
                              fieldState: { error }
                            }) => (
                              <Select
                                options={[
                                  { value: 'ftl', label: 'FTL - puni utovar' },
                                  { value: 'ltl', label: 'LTL - zbirni transport' },
                                ]}
                                placeholder="Odaberite opciju..."
                                closeMenuOnSelect={true}
                                ref={ref}
                                onChange={onChange}
                                onBlur={onBlur}
                                value={value}
                                selectedOptionStyle="check"
                              />
                            )} />
                        </Box>

                        <Box mb={5}>
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum preuzimanja</Text>
                          <Input  {...register('loading_date', { required: true })} min={todayDate()} type={'date'} defaultValue={valueDate(request?.loadingDate)}></Input>
                        </Box>

                        <Box mb={5}>
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Datum dostave</Text>
                          <Input  {...register('unloading_date', { required: true })} min={todayDate()} type={'date'} defaultValue={valueDate(request?.unloadingDate)}></Input>
                        </Box>

                        <Box mb={5}>
                          <Stack direction={['column', 'row']}>
                            <Box w={'full'}>
                              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Masa tereta</Text>
                              <InputGroup>
                                <Input defaultValue={request?.weight} {...register('weight')} />
                                <InputRightAddon children='kg' />
                              </InputGroup>
                            </Box>
                            <Box w={'full'}>
                              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Dimenzije (Dužina x Širina x Visina)</Text>
                              <InputGroup>
                                <Input defaultValue={request?.dimensionsLength} placeholder={'D'} {...register('dimensions_length')} type={'number'} />
                                <Input defaultValue={request?.dimensionsWidth} placeholder={'Š'} {...register('dimensions_width')} type={'number'} borderLeft={0} borderLeftRadius={0} />
                                <Input defaultValue={request?.dimensionsHeight} placeholder={'V'} {...register('dimensions_height')} type={'number'} borderLeft={0} borderLeftRadius={0} />
                                <InputRightAddon children='cm' />
                              </InputGroup>
                            </Box>
                          </Stack>
                        </Box>

                        <Box mb={5}>
                          <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Prostor (m³)</Text>
                          <InputGroup>
                            <Input defaultValue={request?.space} {...register('space')} />
                            <InputRightAddon children='m³' />
                          </InputGroup>
                        </Box>

                        <Box mb={5}>
                          <HStack>
                            <Box w={'full'}>
                              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj paleta</Text>
                              <InputGroup>
                                <Input defaultValue={request?.pallets} type={'number'} {...register('pallets')} />
                                {/*<ChakraSelect bg={'gray.100'} borderLeftRadius={0} borderLeft={0}>
                <option value={'euro'}>Euro</option>
            </ChakraSelect>*/}
                              </InputGroup>
                            </Box>
                            <Box w={'full'}>
                              <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Broj komada</Text>
                              <Input defaultValue={request?.packages} type={'number'} {...register('packages')} />
                            </Box>
                          </HStack>
                        </Box>

                        <Service defaultValue={request?.isRamp} technicalName={'is_ramp'} control={control} name={'Potrebna rampa'} description={'Označite ovu opciju ukoliko vam je potrebna rampa na vozilu.'} color={'green'} icon={FaTruckLoading} />
                        <Service defaultValue={request?.isAdr} technicalName={'is_adr'} control={control} name={'ADR - Opasna roba'} description={'Označite ovu opciju ukoliko je vaš teret ili roba opasna.'} color={'red'} icon={FaFire} />
                        <Service defaultValue={request?.isFrigo} technicalName={'is_frigo'} control={control} name={'FRIGO'} description={'Označite ovu opciju ukoliko vam je potrebno vozilo sa hladnjačama.'} color={'blue'} icon={FaSnowflake} />
                        <Service defaultValue={request?.isFragile} technicalName={'is_fragile'} control={control} name={'Lomljivo'} description={'Označite ovu opciju ukoliko su roba ili teret lomljivi.'} color={'orange'} icon={GiShatteredGlass} />
                      </Box>
                      <Box w={'full'}>
                        <Textarea defaultValue={request?.note} {...register('note')} placeholder={'Dodatna napomena...'}></Textarea>

                        <Spacer h={'25px'} />

                        <Text fontSize={'xs'} color={'gray.500'} fontWeight={'bold'} mb={2}>Potrebna vozila</Text>

                        {vehiclesData?.vehicles?.map((vehicle) => (
                          <Vehicle defaultValue={request?.vehicles?.filter((v) => v.id == vehicle.id)?.length > 0} id={vehicle.id} control={control} controlName={'vehicle'} key={vehicle.id} vehicle={vehicle} />
                        ))}
                      </Box>
                    </HStack>
                  </Box>
                </form>
              </TabPanel>
              <TabPanel>
                <TableContainer borderRadius={'md'} boxShadow={'base'}>
                  <Table boxShadow={'base'} variant={'primary'}>
                    <Thead>
                      <Tr>
                        <Th>#</Th>
                        <Th>Kompanija</Th>
                        <Th>Ponuda</Th>
                        <Th>Datum ponude</Th>
                        <Th>Ocjena</Th>
                        <Th>Status</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {request?.offers?.map((offer) => (
                        <Tr>
                          <Td><Badge>{offer?.id}</Badge></Td>
                          <Td>
                            <HStack spacing={5}>
                              <Avatar size={'md'} name={offer?.company?.name} src={offer?.company?.logoPath}>
                                <Tooltip textAlign={'center'} hasArrow label={`Ocjena prijevoznika ${offer?.company?.rating} od 5 na osnovu prethodni vožnji.`}>
                                  <AvatarBadge boxSize='2.5em' color={'black'} bg={'gold'} fontSize={'12px'} fontWeight={'bold'}>{offer?.company?.rating}</AvatarBadge>
                                </Tooltip>
                              </Avatar>
                              <VStack spacing={0} alignItems={'left'}>
                                <Text fontSize={'md'}>{offer?.company?.name}</Text>
                                <Text color={'gray'} fontSize={'sm'}>prije {offer?.createdAtBefore}.</Text>
                              </VStack>
                            </HStack>
                          </Td>
                          <Td>
                            <Text>{offer?.amount}</Text>
                          </Td>

                          <Td>
                            <Text fontSize={'sm'}>{displayDate(offer.createdAt)}</Text>
                          </Td>
                          <Td>
                            <Text>{offer?.rating}</Text>
                          </Td>
                          <Td>
                            {offer?.accepted && <Badge colorScheme={'green'}>Ponuda odabrana</Badge>}
                          </Td>
                          <Td>
                            <Menu size={'md'}>
                              <MenuButton as={Button} rightIcon={<FiChevronDown />}>
                                Opcije
                              </MenuButton>
                              <MenuList>
                                <MenuItem onClick={(e) => updateRequestOffer({ id: offer.id, requestId: request?.id, accepted: true })} p={3} icon={<FiCheckCircle />}>Postavi kao odabranu ponudu</MenuItem>
                                <MenuItem onClick={(e) => deleteRequestOffer({ requestId: request?.id, requestOfferId: offer?.id })} p={3} color={'red'} icon={<FiTrash />}>Obriši</MenuItem>
                              </MenuList>
                            </Menu>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
              </TabPanel>
            </TabPanels>
          </Tabs>


        </>
      }

      <Spacer h={'150px'} />

    </>
  )
};

export default Request;