import { ICloseFreightRequest, ICreateFreightRequest, IMeta, IMetaRequest, IMetaRequestFreightRequest, INotification, IRequest, IUser, IUserCreateRequest } from "../../resources/interfaces";
import { baseApi } from "./base/base";

export const notificationsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<{ notifications: Array<INotification>, meta: { pagination: IMeta } }, IMetaRequest>({
      query: (meta: IMetaRequest) => ({ url: `/notifications`, method: 'GET', params: meta }),
      providesTags: ['Notification']
    }),
    clearNotifications: builder.mutation<{ notification: INotification }, void>({
      query: () => ({ url: `/notifications/clear`, method: 'POST' }),
      invalidatesTags: ['Notification', 'User']
    }),
  }),
  overrideExisting: false
})

export const { useGetNotificationsQuery, useClearNotificationsMutation } = notificationsApi;
